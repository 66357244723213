@import "../../styles/dims";

.membership-information-page {
  background-color: var(--white);

  &--membership-benefits {
    padding-bottom: 0;
  }

  &--membership-additional-benefits {
    padding-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-main) {
    &--blurb {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: left;
    }

    &--benefits-title {
      h3 {
        text-align: left;
      }
    }
  }
}

.section--title {
  font-size: 1rem;
}