@import '../../styles/dims';

.checkout-form {
  &--group-email {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--group-customer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--group-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--submit {
    //margin-top: 3.25rem;

    &._margin-top-none {
      margin-top: 0;
    }
  }

  &--expiration-date {
    width: 7rem;

    .text-input--label {
      overflow: visible;
      white-space: nowrap;
    }
  }

  &--expiration-month {
    width: 3rem;

    .text-input--label {
      overflow: visible;
      white-space: nowrap;
    }
  }

  &--security-code {
    width: 3.5rem;
    margin-left: 1.5rem;
  }

  &--zip-code {
    width: 7rem;
  }

  &--form-card {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  &--payment-methods {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    img {
      margin-right: 0.3rem;
      margin-top: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--form-group {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .text-input {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }

  &--form-group-names {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    .text-input {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  &--customer-first-name {
    flex-basis: 85%;
    padding-top: 0rem !important;
  }

  &--customer-first-name {
    flex-basis: 85%;
    padding-top: 0rem !important;
  }

  &--customer-middle-initial {
    flex-basis: 15%;
    padding-top: 0rem !important;
  }

  &--group-address {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--form-group-state {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    .text-input {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  &--state {
    flex-basis: 60%;
  }

  &--donor-zip-code {
    flex-basis: 40%;
  }

  &--date-group {
    width: 90%;
  }

  &--btns {
    margin-top: 2.1875rem;

    &.checkout-form--btns-multi {
      display: flex;
      flex-direction: row;

      .btn {
        &:first-child {
          margin-right: 0.5rem;
        }

        &:last-child {
          margin-left: 0.5rem;
        }
      }
    }

    &.checkout-form--btns-single {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;

      .btn {
        margin-top: 1rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .checkout-form--expiration-month {
    width: 10%;
  }

  .checkout-form--expiration-year {
    width: 15%;
  }

  .checkout-form--security-code {
    width: 30%;
  }

  .checkout-form--telephone {
    width: 50%;
    padding-right: 0.25rem;
  }

  .checkout-form--your-email-confirm {
    margin-bottom: 1.5rem;
  }

  .checkout-form--address {
    padding-top: 0rem !important;
  }

  .error-list {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: $breakpoint-main) {
    &--group-email {
      height: 200px;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    &--date-group {
      width: 50%;
    }

    &--group-customer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--group-address {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--group-email {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--group-card {
      width: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--your-email {
      width: calc(50% - 0.5rem);
    }

    &--your-email-confirm {
      width: calc(50% - 0.5rem);
      padding-left: 0.25rem;
    }

    &--customer-first-name {
      // width: calc(40% - 0.5rem);
      flex-basis: 90%;
    }

    &--customer-middle-initial {
      // width: calc(10% - 0.5rem);
      flex-basis: 11%;
    }

    &--customer-last-name {
      // width: calc(50% - 0.5rem);
      flex-basis: 100%;
      margin-left: 1rem;
      padding-top: 0rem !important;
    }

    &--city {
      // width: calc(50% - 0.5rem);
      flex-basis: 100%;
      margin-right: 1rem;
    }

    &--customer-state {
      flex-basis: 90%;
    }

    &--customer-zip-code {
      flex-basis: 11%;
    }
  }

  .StripeElement {
    color: var(--text-dark);
    background: var(--very-light-fill);
    border: 1px var(--med-accent) solid;
    border-radius: 1px;
    box-shadow: none !important;
    line-height: 1.2 !important;
    padding: 0.5rem 0.625rem;

    iframe {
      // height: 19px !important;
      vertical-align: middle;
    }
  }

  .StripeElement--invalid {
    border-color: var(--red);
    color: var(--text-dark);

    label {
      color: var(--red);
    }
    // color: var(--red);
  }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus {
  // 	background-color: rgb(16, 48, 104) !important;
  // 	background-image: none !important;
  // 	color: rgb(0, 0, 0) !important;
  // 	transition: background-color 2000s ease-in-out 0s;
  // }

  input::placeholder {
    color: #aab7c400;
  }

  input:focus,
  .StripeElement--focus {
    outline: 1px var(--blue) solid;
    outline-offset: 0;
    box-shadow: none;
  }

  input[type='radio']:focus {
    outline: none;
  }
}
