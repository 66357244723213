@import 'styles/dims';

.app {
  background-color: var(--light-fill);
  text-align: left;
  min-height: 100vh;

  &--centered {
    width: 100%;
    max-width: $non-bleed-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &--inset {
    width: 100%;
    max-width: $inset-section-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.page {
  min-height: calc(100vh - 7.25rem);
}
