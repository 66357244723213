.question-box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.question-box--indicator {
  opacity: 1;
}
.question-box--indicator-image {
  width: 1.25rem;
  height: 0.625rem;
  transform: rotate(0deg) scale(0.5);
  transition: transform 200ms linear;
  transform-origin: 50% 50%;
  outline: 0;
}
.question-box--question {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.question-box--question-text {
  color: var(--blue);
  font-size: 1.125rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-left: 1rem;
  margin: 0;
}
.question-box--answer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 0;
  transition: height 200ms linear;
  overflow: hidden;
}
.question-box--answer a {
  color: var(--blue);
}
.question-box--answer br {
  margin-bottom: 1rem;
}
.question-box--answer-inner {
  opacity: 1;
}
.question-box--answer-text {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 1rem 0 0 2.25rem;
  margin: 0;
}
.question-box.active .question-box--question-text {
  color: var(--text-dark);
}
.question-box.active .question-box--indicator-image {
  transform: rotate(180deg) scale(0.5);
}
@media screen and (min-width: 48rem) {
  .question-box {
    align-items: flex-start;
  }
}

