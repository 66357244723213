.success-list {
  border: 1px #55d189 solid;
  padding: 0.5rem 0.75rem;
  color: #55d189;

  &--message {
    margin: 0 0 0.5rem 0;
    color: #55d189;

    &::before {
      content: '';
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      background: transparent url('~/public/images/icons/success-icon.svg') no-repeat center center;
      background-size: cover;
      position: relative;
      top: 0.125rem;
      margin-right: 0.5rem;
    }
  }

  &--list {
    color: #55d189;
    margin: 0 0 0.5rem 0;
    padding-left: 1rem;

    li {
      font-size: 0.875rem;
      line-height: 1.4;
      list-style: none;
    }
  }
}
