.faq-page {
  background-color: var(--white);
}
.faq-page--blurb p {
  margin: 0;
}
.faq-page .card {
  margin: 0 0.5rem 1px 0.5rem;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .faq-page--blurb p {
    text-align: left;
  }
  .faq-page--blurb .question-box {
    align-items: flex-start;
  }
  .faq-page--questions .question-box .section--inner.app--centered {
    margin: 0;
    padding: 0;
  }
}

