@import "../../styles/dims";

.enrollment-summary {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

  @media screen and (min-width: $breakpoint-main) {
    display: flex;
    -ms-flex-align: end;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 90%;
  }

  &--details-row {
    flex-direction: row;
    display: flex;
  }

  &--details-edit {
    text-align: right;

    .btn {
      margin-left: 0.5rem;
    }
  }

  &--details-toggle {
    flex-grow: 1;
    align-self: flex-start;
    cursor: pointer;
    color: var(--blue);
    line-height: 1.4;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-top: 12px;
    width: auto;
    font-size: 16px;
  }
}

.display-block {
  display: block;
}

.text-align-right {
  text-align: right;
}

.grey {
  color: #757575;
}

.margin-top {
  margin-top: 2rem;
}
