.total {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;

  &--summary {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--grand-qty {
    font-size: 1.125rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--text-darker);
    padding-top: 0.2rem;
  }

  &--grand-total {
    color: var(--text-darker);
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: right;
  }

  &--qty {
    font-size: 35px;
    padding-right: 0.3rem;
  }

  p {
    font-size: 1rem;
    margin: 0;

    &.total--grand-total {
      font-size: 1.125rem;
      color: #757575;
      text-align: right;
    }

    &.total--grand-qty {
      font-size: 1.125rem;
      color: #757575;
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  &--value {
    font-weight: 700;
    font-size: 1rem;
    display: inline-block;
    vertical-align: baseline;
    color: #757575;
    padding-left: 0.3rem;
  }
}
