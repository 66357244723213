.privacy-page {
  background-color: var(--white);

  a {
    color: var(--blue);
    text-decoration: underline;

    &[href^="tel"] {
      text-decoration: none;
      font-weight: 500;
    }
  }

  li {
    margin-bottom: 0.5rem;
  }

  h3 {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}
