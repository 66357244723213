@import "../../styles/dims";

.hero {
  background: var(--text-darker);
  height: auto;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;

  &--inner {
    padding: 3rem 1rem;
  }

  &--title {
    color: #fff;
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    white-space: pre-line;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  }

  &--subtitle {
    color: #fff;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.45;
    margin-top: 0.825rem;
    margin-bottom: 0;
    white-space: pre-line;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  }

  &-image {
    min-height: 10.25rem;
    background-position: center 40%;
    background-size: cover;
    background-repeat: no-repeat;

    .hero--inner {
      min-height: 10.25rem;
    }
  }

  &-no-image {
    background: var(--white);

    .hero--title {
      color: #757575;
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      text-shadow: none;
      font-size: 20px;
    }

    .hero--subtitle {
      color: var(--text-darker);
    }

    .hero--inner {
      justify-content: center;
      padding: 1.75rem 1.25rem 1rem;
    }

    .hero--header {
      align-self: flex-start;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    .hero--inner {
      padding: 3.75rem 1rem;
    }

    .hero--title {
      font-size: 2.125rem;
    }

    &-no-image {
      .hero--inner {
        padding: 3.75rem 1rem 0 1rem;
      }
    }
  }
}

[data-page="faq"] {
  .hero-image {
    background-position: center 63%;
  }
}

[data-page="contactus"] {
  .hero-image {
    background-position: center 63%;
  }
}

.deploy00001 {
  padding-bottom: 0;
}

@media screen and (min-width: $breakpoint-main) {
  [data-page="gift-thankyou"] {
    .hero-image {
      background-position: center 28%;
    }
  }

  [data-page^="choose-membership"] {
    .hero-image {
      background-position: center 22%;
    }
  }
}
