@import "../../styles/dims";

.faq-page {
  background-color: var(--white);

  &--blurb {
    p {
      margin: 0;
    }
  }

  .card {
    margin: 0 0.5rem 1px 0.5rem;
    flex-direction: column;
  }

  @media screen and (min-width: $breakpoint-main) {
    &--blurb {
      p {
        text-align: left;
      }

      .question-box {
        align-items: flex-start;
      }
    }

    &--questions {
      .question-box {
        .section--inner.app--centered {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
