.text-input {
  padding: 0.8rem 0;
  position: relative;
}
.text-input--label {
  color: var(--text-darker);
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.4rem;
  position: relative;
  min-height: 1.1875rem;
}
.text-input--label span {
  position: absolute;
  width: auto;
  top: 0;
  left: 0;
  white-space: nowrap;
}
.text-input--error {
  color: var(--red);
}
.text-input--error::after {
  color: var(--red);
}
.text-input--required.text-input--label-overflow::after {
  display: none;
}
.text-input--required.text-input--label-overflow span::after {
  content: " *";
  color: var(--red);
  white-space: nowrap;
}
.text-input--required::after {
  content: " *";
  color: var(--red);
  white-space: nowrap;
}
.text-input--required:empty::after {
  display: none;
}
.text-input.span-labeling .text-input--required::after {
  content: "";
}
.text-input.span-labeling .text-input--required span {
  left: 0;
}
.text-input.span-labeling .text-input--required span::after {
  content: " *";
  color: var(--red);
  white-space: nowrap;
}
.text-input--input {
  color: var(--text-dark);
  background: var(--very-light-fill);
  border: 1px var(--med-accent) solid;
  border-radius: 1px;
  box-shadow: none !important;
  font-size: 1rem;
  line-height: 1.2;
  padding: 0.5rem 0.625rem;
  width: 100%;
}
.text-input--input.text-input--textarea {
  background-color: var(--white);
  color: var(--text-dark);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  min-height: 10rem;
  padding: 0.825rem 0.875rem;
}
.text-input--input.text-input--date {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  width: 100%;
  vertical-align: middle;
}
.text-input--input:focus {
  outline: 1px var(--blue) solid;
  outline-offset: 0;
  box-shadow: none;
}
.text-input--input-wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  width: auto;
}
.text-input--input-wrapper .text-input--icon {
  display: inline-block;
  width: 1.75rem;
  height: 1.875rem;
  right: 9px;
  margin-left: 0.5rem;
  position: absolute;
  top: 4px;
  vertical-align: middle;
}
.text-input--input-wrapper.text-input--date-native {
  margin: 0;
  padding: 0;
  width: 8.875rem;
  position: relative;
}
.text-input--input-wrapper.text-input--date-native .text-input--icon {
  background: var(--white);
  border-left: 1px var(--med-accent) solid;
  display: inline-block;
  width: 2.375rem;
  height: 2.5rem;
  padding: 0.3125rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}
.text-input.error .text-input--label {
  color: var(--red);
}
.text-input.error .text-input--label::after {
  color: var(--red);
}
.text-input.error .text-input--input {
  border-color: var(--red);
  color: var(--red);
}

