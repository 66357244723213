.location-select-page {
  background-color: var(--white);
}
.location-select-page--submit {
  margin-top: 2rem;
}
.location-select-page .customer-location-input {
  margin-bottom: 1rem;
}
.location-select-page--help a {
  color: var(--blue);
}

#stateProvinceState:focus {
  width: 568px;
  position: absolute;
  z-index: 1;
}

#no-results {
  margin-top: 3.3rem;
}

.bold {
  font-weight: bold;
}

