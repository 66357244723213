@import "../../styles/dims";

.card-membership-type {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  border: 1px solid var(--gray-card-border);
  min-height: 8.125rem;

  &--name {
    order: 0;
    flex-grow: 2;
    font-size: 1.625rem;
    color: var(--text-darker);
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    flex-basis: 70%;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--description {
    padding: 0 !important;

    p:last-of-type {
      display: inline;
    }
  }

  &--prices {
    float: right;
    flex-grow: 1;
    text-align: right;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: var(--text-dark);
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    flex-basis: 30%;
    max-width: 35%;
    white-space: nowrap;
  }

  &--cost {
    order: 1;
    float: right;
    flex-grow: 1;
    text-align: right;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: var(--text-dark);
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    margin-top: 0.7rem;
    white-space: nowrap;

    strong {
      font-size: 1.25rem;
      line-height: 1rem;
      font-weight: 400;
    }
  }

  &--enrollment {
    margin-top: 1rem;
    float: right;
    flex-grow: 1;
    text-align: right;
    line-height: 1.25rem;
    color: var(--text-dark);
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    flex-basis: 30%;
    max-width: 30%;
    font-size: 1rem;
    white-space: nowrap;

    strong {
      margin-left: 5px;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 400;
    }
  }
  // &.has-fees {
  // 	.card-membership-type--cost::after {
  // 		content: ' *';
  // 		color: var(--red);
  // 		font-weight: 500;
  // 	}
  // }

  &--details {
    float: left;
    flex-grow: 2;
    overflow: hidden;
    flex-basis: 70%;
    max-width: 65%;
    align-self: flex-start;
    max-height: max-content;

    p {
      margin: 0;
      font-family: 'Roboto', sans-serif;
    }
  }

  // &--fees {
  // 	order: 2;
  // 	flex-basis: 100%;
  // 	flex-grow: 3;
  // }

  &--learn-more {
    margin-left: 1rem;
    color: var(--blue);
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  &--select {
    margin-top: 2rem;
    order: 2;
    flex-grow: 1;
    text-align: right;
    align-self: flex-end;
  }

  // &--fees-inner {
  // 	float: right;
  // 	padding-bottom: 1rem;
  // }

  &--fee {
    text-align: left;
    margin-top: 0.25rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &--adj {
    font-size: 0.75rem;
    margin: 0;
  }

  &--adj-description {
    font-size: 0.75rem;
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-main) {
    &--details {
      padding-bottom: 0;
      max-width: 100%;
      flex-basis: 100%;
    }

    &--prices {
      flex-basis: 100%;
      max-width: 100%;
    }

    &--cost {
      max-width: 100%;
      flex-basis: 100%;
      float: left;
    }

    &--enrollment {
      margin-top: 0;
      float: left;
      max-width: 100%;
      flex-basis: 100%;
    }

    &--select {
      margin-top: 0;
      align-self: flex-end;
    }

    #prices {
      float: left;
    }
  }
}

.fill {
  order: 4;
  max-width: 70%;
  flex-basis: 70%;
}