.terms-page {
  background-color: var(--white);
}
.terms-page a {
  color: var(--blue);
  text-decoration: underline;
}
.terms-page a[href^=tel] {
  text-decoration: none;
  font-weight: 500;
}
.terms-page li {
  margin-bottom: 0.5rem;
  font-weight: 400;
}
.terms-page h3 {
  margin-top: 1.5rem;
}
.terms-page h3:first-child {
  margin-top: 0;
}
.terms-page h3 strong {
  font-weight: 500;
}

