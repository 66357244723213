@import "../../styles/dims";

.membership-benefits {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
  width: 100%;

  &--glance {
    align-items: baseline;
    display: flex;
    flex-grow: 3;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    order: 1;
    margin-top: 0;
  }

  &--name {
    flex-grow: 2;
    font-size: 1.125rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #757575;
    margin: 0;
  }

  &--details {
    order: 2;
    flex-grow: 3;
    height: max-content;
  }

  &--content {
    line-height: 1;
    font-size: 0.8rem;
    font-family: 'Roboto', sans-serif;
  }

  &--group-info {
    display: inline-block;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    width: 100%;
  }

  &--hr-column {
    text-align: center !important;
    margin-left: 3%;
    width: 97%;
    height: 1px;
    background-color: var(--gray-card-border);
    border: none;
    display: block;
  }

  &--first-column {
    border: 1px solid var(--gray-card-border);
    text-align: center;
    padding: 1% 3%;
  }

  &--second-column {
    border: 1px solid var(--gray-card-border);
    text-align: center;
    padding: 1% 3%;
  }

  &--third-column {
    border: 1px solid var(--gray-card-border);
    text-align: center;
    padding: 1% 3%;
  }

  &--column-value {
    font-size: 3rem;
    font-weight: bold;
    line-height: 2px;
    // margin: 30px;
    color: var(--blue);
  }

  // &--first-column {
  // 	width: 100%;
  // 	flex-direction: column;
  // 	order: 0;
  // }

  // &--second-column {
  // 	margin-right: 0.25rem;
  // 	width: calc(50% - 0.25rem);
  // 	flex-direction: column;
  // 	order: 2;
  // }

  // &--third-column {
  // 	margin-left: 0.25rem;
  // 	width: calc(50% - 0.25rem);
  // 	flex-direction: column;
  // 	order: 3;
  // }

  &--column-label {
    color: var(--text-dark);
    display: block;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    width: 100%;

    &:first-child {
      padding-bottom: 0.75rem;
      padding-top: 0.125rem;
      border-bottom: 1px var(--light-fill) solid;
    }

    &:last-child {
      padding-top: 0.75rem;
      border-top: 1px var(--light-fill) solid;
    }
  }

  @media screen and (min-width: 50px) {
    &--group-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--first-column {
      width: calc(33% - 0.5rem);
      // padding-right: 0.25rem;
      // margin-right: 0.25rem;
      // width: auto;
      // flex-grow: 1;
      // order: 0;
    }

    &--second-column {
      width: calc(33% - 0.5rem);
      // padding-right: 0.25rem;
      // margin-right: 0.25rem;
      // margin-left: 0.25rem;
      // width: auto;
      // flex-grow: 1;
      // order: 1;
    }

    &--third-column {
      width: calc(33% - 0.5rem);
      // padding-right: 0.25rem;
      // margin-left: 0.25rem;
      // width: auto;
      // flex-grow: 1;
      // order: 2;
    }

    &--column-label {
      font-size: 0.8rem;
    }

    &--column-value {
      font-size: 1.5rem;
    }
  }

  &.exception {
    .card-membership-stat--value span::after {
      content: ' *';
      color: var(--red);
      transform-origin: 50% 0;
      transform: scale(0.75);
      display: inline-block;
    }
  }
}

// .notactive {
// 	width: calc(33% - 0.5rem);
// 	padding-right: 0.25rem;
// 	display: inline-block;
// 	justify-content: space-between;
// }