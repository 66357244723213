.loading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.loading p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: var(--text-dark);
  padding: 3rem 0;
}

