.review-enrollment-page {
  background-color: var(--white);

  &--submit {
    margin-top: 3.25rem;
  }

  &--terms {
    font-size: 0.875rem;
    color: var(--text-base);
    margin-top: 2rem;
  }

  &--terms-link {
    color: var(--blue);
  }
}
