@import '../../styles/dims';

.pin-page {
  background-color: var(--white);

  .pin-form--pin {
    margin-top: 1rem;
  }

  &--submit {
    margin-top: 2rem;
  }

  &--learn-more {
    margin-top: 1rem;
    color: var(--blue);
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  &--description {
    color: #757575;
    padding-bottom: 0.4rem;
  }

  &--name {
    color: #757575;
  }

  &--giver-info {
    color: #757575;
  }

  @media screen and (min-width: $breakpoint-main) {
    .claim-page--info {
      padding-bottom: 2rem;
      padding-top: 2rem;
      margin-top: 1rem;
    }

    h3 {
      text-align: center;
    }

    .icon-text-box {
      width: 25%;
      display: inline-block;
      vertical-align: top;
      border: 0;
      padding-bottom: 1rem;
      text-align: center;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .icon-text-box--icon-wrapper {
        margin: 0 auto 1rem auto;
      }

      .icon-text-box--text-wrapper {
        padding: 0;
        margin: 0 auto;
      }
    }
  }
}
