.text-input {
  padding: 0.8rem 0;
  position: relative;

  &--label {
    color: var(--text-darker);
    display: block;
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 0.4rem;
    position: relative;
    min-height: 1.1875rem;

    span {
      position: absolute;
      width: auto;
      top: 0;
      left: 0;
      white-space: nowrap;
    }
  }

  &--required {
    &.text-input--label-overflow {
      &::after {
        display: none;
      }

      span::after {
        content: ' *';
        color: var(--red);
        white-space: nowrap;
      }
    }

    &::after {
      content: ' *';
      color: var(--red);
      white-space: nowrap;
    }

    &:empty {
      &::after {
        display: none;
      }
    }
  }

  &.span-labeling {
    .text-input--required {
      &::after {
        content: '';
      }

      span {
        left: 0;

        &::after {
          content: ' *';
          color: var(--red);
          white-space: nowrap;
        }
      }
    }
  }

  &--input {
    color: var(--text-dark);
    background: var(--very-light-fill);
    border: 1px var(--med-accent) solid;
    border-radius: 1px;
    box-shadow: none !important;
    font-size: 1rem;
    line-height: 1.2;
    padding: 0.5rem 0.625rem;
    width: 100%;

    &.text-input--textarea {
      background-color: var(--white);
      color: var(--text-dark);
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.43;
      min-height: 10rem;
      padding: 0.825rem 0.875rem;
    }

    &.text-input--date {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.4;
      width: 100%;
      vertical-align: middle;
    }

    &:focus {
      outline: 1px var(--blue) solid;
      outline-offset: 0;
      box-shadow: none;
    }
  }

  .text-input--date-native {
    .text-input--input.text-input--date {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.4;
      height: 2.5rem;
      width: 11.25rem;
      padding-right: 2.5rem;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  &--input-wrapper {
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;

    .text-input--icon {
      display: inline-block;
      width: 1.75rem;
      height: 1.875rem;
      right: 9px;
      margin-left: 0.5rem;
      position: absolute;
      top: 4px;
      vertical-align: middle;
    }

    &.text-input--date-native {
      margin: 0;
      padding: 0;
      width: 8.875rem;
      position: relative;

      .text-input--icon {
        background: var(--white);
        border-left: 1px var(--med-accent) solid;
        display: inline-block;
        width: 2.375rem;
        height: 2.5rem;
        padding: 0.3125rem;
        margin-left: 0.5rem;
        vertical-align: middle;
      }
    }
  }

  &--description {
    font-size: 0.75rem;
    color: var(--text-dark);
    line-height: 1.43;
    margin: 0.375rem 0 0 0;
  }

  &--suggestions {
    border: 1px var(--blue) solid;
    position: absolute;
    top: calc(100% - 1rem - 1px);
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    padding: 0.5rem 0;
    margin: 0;
    z-index: 1;
  }

  &--suggestion-item {
    padding: 0.5rem 0.625rem;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--text-darker);
    line-height: 1;
    list-style-type: none;
    cursor: pointer;

    // &:hover {
    // 	background: rgba(0, 0, 0, 0.05);
    // }

    &.hilight {
      background: rgba(0, 0, 0, 0.05);
    }

    &.selected {
      font-weight: 700;
    }
  }

  &.error {
    .text-input--label {
      color: var(--red);

      &::after {
        color: var(--red);
      }
    }

    .text-input--input {
      border-color: var(--red);
      color: var(--red);
    }
  }

  &--date-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}
