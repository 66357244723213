.card-membership-stat {
  display: flex;
  flex-basis: auto;
  margin-top: 0.5rem;
  text-align: center;
  border: 1px solid var(--gray-card-border);
}
.card-membership-stat--label {
  color: var(--text-dark);
  display: block;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}
.card-membership-stat--label:first-child {
  padding-bottom: 0.75rem;
  padding-top: 0.125rem;
  border-bottom: 1px var(--light-fill) solid;
}
.card-membership-stat--label:last-child {
  padding-top: 0.75rem;
  border-top: 1px var(--light-fill) solid;
}
.card-membership-stat--value {
  color: var(--blue);
  font-size: 3.125rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  line-height: 1;
  padding: 0.75rem 0 0.8rem 0;
}
.card-membership-stat.exception .card-membership-stat--value span::after {
  content: " *";
  color: var(--red);
  transform-origin: 50% 0;
  transform: scale(0.75);
  display: inline-block;
}

