.choose-membership-page {
  background-color: var(--white);
}
.choose-membership-page .card {
  margin-top: 0.6rem;
}
.choose-membership-page .card:first-of-type {
  margin-top: 0;
}
.choose-membership-page .card.card-vertical {
  margin: 0 0.5rem;
}
.choose-membership-page .card.card-vertical:first-of-type {
  margin-top: 0.5rem;
}
.choose-membership-page--location {
  color: var(--text-darker);
}
.choose-membership-page--warning {
  color: var(--red);
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
}
.choose-membership-page .btn-try-another {
  margin-top: 3.25rem;
}

.disclosure-legend {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal !important;
  align-items: center;
  border: 1px solid var(--gray-card-border);
  display: block;
  margin: auto;
  background-color: #d3d3d3 !important;
  padding: 10px;
  text-align: left;
  font-weight: 300 !important;
}

