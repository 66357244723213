.pin-page {
  background-color: var(--white);
}
.pin-page .pin-form--pin {
  margin-top: 1rem;
}
.pin-page--submit {
  margin-top: 2rem;
}
.pin-page--learn-more {
  margin-top: 1rem;
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.pin-page--description {
  color: #757575;
  padding-bottom: 0.4rem;
}
.pin-page--name {
  color: #757575;
}
.pin-page--giver-info {
  color: #757575;
}
@media screen and (min-width: 48rem) {
  .pin-page .claim-page--info {
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 1rem;
  }
  .pin-page h3 {
    text-align: center;
  }
  .pin-page .icon-text-box {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    padding-bottom: 1rem;
    text-align: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .pin-page .icon-text-box .icon-text-box--icon-wrapper {
    margin: 0 auto 1rem auto;
  }
  .pin-page .icon-text-box .icon-text-box--text-wrapper {
    padding: 0;
    margin: 0 auto;
  }
}

