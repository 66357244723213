.section {
  position: relative;
}
.section-padding-all {
  padding: 1rem 1.25rem;
}
.section-padding-no-top {
  padding: 0 1.25rem 1rem 1.25rem;
}
.section-padding-no-bottom {
  padding: 1rem 1.25rem 0 1.25rem;
}
.section-padding-vertical {
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.section-padding-horizontal {
  padding: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.section-padding-top {
  padding: 0;
  padding-top: 1rem;
}
.section-padding-bottom {
  padding: 0;
  padding-bottom: 1rem;
}
.section-padding-left {
  padding: 0;
  padding-left: 1.25rem;
}
.section-padding-right {
  padding: 0;
  padding-right: 1.25rem;
}
.section-padding-none {
  padding: 0;
}
.section > p:first-child {
  margin-top: 0;
}
.section > p:first-child::first-line {
  line-height: 1;
}
.section.alt-section {
  background: var(--light-fill);
}
@media screen and (min-width: 48rem) {
  .section {
    padding: 1.125rem 0;
  }
  .section-padding-all {
    padding: 1.125rem 0;
  }
  .section-padding-no-top {
    padding: 0 0 1.125rem 0;
  }
  .section-padding-no-bottom {
    padding: 1.125rem 0 0 0;
  }
  .section-padding-vertical {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .section-padding-horizontal {
    padding-left: 0;
    padding-right: 0;
  }
  .section-padding-top {
    padding-top: 1.125rem;
  }
  .section-padding-bottom {
    padding-bottom: 1.125rem;
  }
  .section-padding-left {
    padding-left: 0;
    padding-right: 0;
  }
  .section-padding-right {
    padding-left: 0;
    padding-right: 0;
  }
  .section-padding-none {
    padding: 0;
  }
  .section .section--inner {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.section--title {
  color: #757575;
  font-size: 1.325rem;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

