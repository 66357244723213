@import "../../styles/dims";

.membership-summary {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;

  &--collapse-bar {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--gift-information {
    color: #757575;
    font-weight: 400;
    width: fit-content;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
  }

  &--collapse-all {
    cursor: pointer;
    color: var(--blue);
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: fit-content;
    font-size: 16px;
    text-align: right;
  }
}