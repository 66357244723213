.home-page {
  white-space: pre-line;
  background-color: var(--white);
  padding-bottom: 0;
}
.home-page .btn-get-started {
  margin-top: 1.5rem;
}
.home-page .hero .hero--title {
  padding-top: 4.75rem;
}
@media screen and (min-width: 48rem) {
  .home-page .hero .hero--title {
    padding-top: 20%;
  }
  .home-page .home-page--blurb {
    text-align: center;
  }
  .home-page .home-page--info {
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 1rem;
  }
  .home-page h3 {
    text-align: center;
  }
  .home-page .icon-text-box {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    padding-top: 0;
    text-align: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .home-page .icon-text-box .icon-text-box--icon-wrapper {
    margin: 0 auto 1rem auto;
  }
  .home-page .icon-text-box .icon-text-box--text-wrapper {
    padding: 0;
    margin: 0 auto;
  }
}

