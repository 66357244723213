@import '../../styles/dims';

.contact-info-form {
  width: 100%;

  &--form-group {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    .text-input {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  &--group-email {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--group-recipient {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
  }

  &--recipient-first-name {
    flex-basis: 85%;
  }

  &--recipient-middle-inital {
    flex-basis: 15%;
  }

  &--send-date {
    .text-input--input-wrapper {
      width: 10rem;
    }
  }

  .text-input {
    &--date {
      background-color: var(--very-light-fill);
      color: var(--text-darker);
    }
  }

  &--btns {
    width: 100%;
    max-width: 37.5rem;
    margin: 2rem auto 0 auto;

    .btn-block {
      max-width: none;
    }

    &.contact-info-form--btns-multi {
      display: flex;
      flex-direction: column;

      .btn {
        margin-bottom: 0.625rem;

        @media screen and (min-width: $breakpoint-main) {
          margin: 0 auto 0.625rem;
        }

        &:first-child {
          //margin-right: 0.5rem;
        }

        &:last-child {
          //margin-left: 0.5rem;
        }
      }
    }

    .contact-info-form--cacncel {
      order: 1;
    }

    .contact-info-form--submit {
      order: 0;
    }

    &.contact-info-form--btns-single {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;

      .btn {
        margin-top: 1rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &--btns-pad {
    padding-top: 1.5rem;
  }

  @media screen and (min-width: $breakpoint-main) {
    &--send-date {
      width: auto;
      display: inline-block;
      vertical-align: bottom;
    }

    &--submit,
    &--cacncel {
      width: calc(50% - 0.5rem);
    }

    &--group-email {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--group-recipient {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--recipient-email {
      width: calc(50% - 0.5rem);
      padding-right: 0.25rem;
    }

    &--recipient-email-confirm {
      width: calc(50% - 0.5rem);
      padding-left: 0.25rem;
    }

    &--recipient-first-name {
      flex-basis: 90%;
    }

    &--recipient-middle-inital {
      flex-basis: 11%;
    }

    &--recipient-last-name {
      flex-basis: 100%;
      margin-left: 1rem;
    }

    &--btns {
      &.contact-info-form--btns-multi {
        flex-direction: row;
        align-self: flex-end;
        margin-right: 0;
        margin-top: 3rem;

        .btn {
          margin-bottom: 0.625rem;

          &:first-child {
            margin-right: 0.5rem;
          }

          &:last-child {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
