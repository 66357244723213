.maintenance-page {
  background-color: var(--white);
  padding-bottom: 0;
}
.maintenance-page .hero .hero--title {
  padding-top: 4.75rem;
}
@media screen and (min-width: 48rem) {
  .maintenance-page .hero .hero--title {
    padding-top: 20%;
  }
  .maintenance-page .maintenance-page--blurb {
    text-align: justify;
  }
  .maintenance-page .maintenance-page--info {
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 1rem;
  }
  .maintenance-page h3 {
    text-align: center;
  }
  .maintenance-page .icon-text-box {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    padding-top: 0;
    text-align: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .maintenance-page .icon-text-box .icon-text-box--icon-wrapper {
    margin: 0 auto 1rem auto;
  }
  .maintenance-page .icon-text-box .icon-text-box--text-wrapper {
    padding: 0;
    margin: 0 auto;
  }
}

