@import "../../styles/dims";

.membership-detail-page {
  background-color: var(--white);
  padding-bottom: 0.5rem;

  &--blurb {
    p {
      margin: 0;
    }
  }

  &--description {
    p {
      margin: 1rem 0 1rem 0;
      font-size: 0.75rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--legal {
    h3 {
      font-size: 1.375rem;
      text-align: left;
    }
  }

  &--benefits {
    .card {
      flex-direction: column;
    }

    .icon-text-box {
      border: 0;
    }
  }

  &--benefits-label {
    h3 {
      font-size: 1.375rem;
      text-align: left;
    }
  }

  &--action {
    margin-top: 2rem;
    margin-bottom: 5rem;

    button {
      margin-left: 1.5rem;
      margin-bottom: 1rem;
      max-width: 45%;
    }
  }

  &--membership-details {
    padding-bottom: 0;
  }

  h3 {
    color: var(--text-darker);
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-main) {
    &--blurb {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: left;
    }

    &--benefits-label {
      h3 {
        text-align: left;
      }
    }

    &--benefits {
      padding-top: 0;

      .card {
        margin: 0;
        padding-top: 3.25rem;
        padding-bottom: 3.25rem;
      }
    }

    &--description {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      p {
        text-align: left;
      }
    }

    .btn-block {
      margin-left: 0;
      max-width: 300px;
    }
  }
}

.select--button {
  float: left;
  z-index: 1;
}
