.payment-details {
  display: block;
  flex-direction: row;
  justify-content: flex-start;
}
.payment-details--btn {
  left: 300px;
  bottom: 54px;
}
@media screen and (max-width: 48rem) {
  .payment-details--btn {
    position: relative;
    left: unset;
    bottom: unset;
  }
}
@media screen and (min-width: 48rem) {
  .payment-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 100px;
  }
  .payment-details--wrapper {
    border: 1px solid var(--gray-card-border);
    width: 100%;
  }
  .payment-details--full {
    flex-basis: 100%;
  }
}
.payment-details .btn-primary-sm {
  background-color: #3d89dd;
  border-radius: 5px;
  height: 42px;
  width: 66px;
  border: none;
  color: white;
  font-size: 16px;
}
.payment-details .text-align-right {
  text-align: right;
}
.payment-details .text-align-center {
  text-align: center;
}

