@import "../../styles/dims";

.membership-item {
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;

  &--recipient {
    flex-grow: 3;
    font-size: 1.125rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: var(--text-darker);
    margin: 0;
    order: 0;
  }

  &--plan {
    flex-grow: 2;
    font-size: 1.125rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #757575;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  &--plan-discounted {
    flex-grow: 2;
    font-size: 1.125rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #757575;
    margin: 0;
  }

  &--net-cost {
    color: #757575;
    flex-grow: 1;
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    margin: 0;
  }

  &--net-cost-discounted {
    color: #3bb54a;
    flex-grow: 1;
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    margin: 0;
  }

  &--enrollment {
    color: #757575;
    flex-grow: 1;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    margin: 0;
    padding-right: 0.4rem;
  }

  &--price {
    margin-top: 5px;
    font-weight: 700;
    color: #757575;
  }

  &--glance {
    align-items: baseline;
    display: flex;
    flex-grow: 3;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    order: 1;
    margin-top: 0;
  }
  .summary-gif-separator {
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: solid;
    border-width: 1px;
    border-color: #e7e3e3;
  }

  .summary-associates-price,
  .summary-associates-name,
  .summary-label-total,
  .text-promo-applied,
  .summary-label-associate,
  .summary-label-membership {
    display: flex;
  }

  .summary-gif-bottom {
    background-color: #D9D9D9;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -18px;
    padding-left: 10px;
    padding-right: 12px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .summary-gif-section {
    background-color: rgb(255, 255, 255);
    padding-top: 10px;
    margin: -20px -20px 0px;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .summary-gif-total {
    background-color: rgb(255, 255, 255);
    margin: -20px -20px 0px;
    display: flex;
    flex-direction: row;
    height: 40px;
    padding-left: 10px;
  }
  .summary-label-gif {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    margin: 0;
    color: #333;
    text-transform: none;
  }
  .summary-gif-header {
    background-color: rgb(153, 204, 255);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    padding-left: 10px;
    padding-right: 12px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;  
    height: 60px;
  }  
  .summary-gif-header-top {
    margin-top: -10px;
  }
  .summary-label-gif {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    margin: 0;
    color: #333;
    text-transform: none;
  }
  .summary-label-gif-for {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    padding-left: 2px;
    margin: 0;
    color: #333;
    text-transform: none;
  } 
  .summary-label-details {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    padding-left: 2px;
    font-size: 0.75rem;
    text-transform: none;
    margin: 0;    
    color: #333;
  }
  .summary-label-details-item {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    padding-left: 4px;
    font-size: 0.75rem;
    margin: 0;    
    text-transform: none;
    color: #757575;
  }
  .summary-label-details-item-cost {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    padding-left: 4px;
    font-size: 0.75rem;
    margin: 0;    
    text-transform: none;
    color: #757575;
    flex-grow: 1;
    text-align: right;
  }
  .summary-label-details-item-discount {
    color: #757575;
    flex-grow: 1;
    font-size: 0.75rem;
    text-decoration: line-through;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.4;
    text-align: right;
    margin: 0;
  }
  .summary-label-details-center {
    display: flex;
    align-items: center;
  }
  .summary-gif-message {
    min-width: 85px;
  }
  .summary-gif-message-details {
    min-width: 180px;
  }
  .summary-label-details-width{
    width: calc(55%);
  }
  .summary-label-details-cost-width{
    width: calc(25%);
    margin-left: 10px;
  }
  .details-cost-space{
    margin-left: 20px;
  }
  &--promo {
    align-items: flex-end;
    display: flex;
    flex-grow: 3;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    order: 1;
    margin-top: 0;
  }

  &--promo-applied {
    flex-grow: 2;
    font-size: 0.75rem;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #3bb54a;
    text-align: left;
    margin: 0;

    &::before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background: transparent url('~/public/images/icons/check.svg') no-repeat center center;
      background-size: cover;
      position: relative;
      margin-right: 0.5rem;
      top: 0.125rem;
    }
  }

  &--cost {
    color: #757575;
    flex-grow: 1;
    font-size: 0.875rem;
    text-decoration: line-through;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.4;
    text-align: right;
    margin: 0;
  }

  &--enroll {
    align-items: baseline;
    display: flex;
    flex-grow: 3;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    order: 1;
    margin-top: 0;
    height: 30px;
  }

  &--fees {
    order: 2;
  }

  .cost-adjustments {
    text-align: right;
    flex-grow: 3;
    flex-basis: 100%;
  }

  &--details {
    order: 3;
    flex-grow: 3;
    height: max-content;
  }

  // &--details-inner {
  // 	padding: 1rem 0;
  // }

  &--additional-note {
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 700;
  }

  &--details-hide {
    cursor: pointer;
    color: var(--blue);
    line-height: 1.4;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-top: 12px;
    width: auto;
    font-size: 0.8rem;
  }

  &--details-view {
    order: 4;
    flex-grow: 1;
    align-self: flex-start;
    cursor: pointer;
    color: var(--blue);
    line-height: 1.4;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-top: 12px;
    width: auto;
    font-size: 16px;
  }

  &--details-edit {
    text-align: right;

    .btn {
      margin-left: 0.5rem;
    }
  }

  &--details-open {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--note {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.4;
    margin: 0 0 1rem 0;
    white-space: pre-line;
  }

  &--fee {
    float: right;

    p {
      text-align: left;
      font-size: 0.75rem;
      margin: 0.5rem 0 0 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &--group-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;

    .summary-value-grey {
      line-height: 1;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    &--details-toggle {
      flex-grow: 1;
      align-self: flex-start;
    }

    &--group-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-end;
    }

    &--first-column {
      width: calc(33% - 0.5rem);
      padding-right: 0.25rem;
    }

    &--second-column {
      width: calc(33% - 0.5rem);
      padding-right: 0.25rem;
    }

    &--third-column {
      width: calc(33% - 0.5rem);
      padding-right: 0.25rem;
    }
  }

  @media screen and (max-width: calc(#{$breakpoint-main} - 1px)) {
  
    .summary-gif-header {
      flex-direction: column;
      align-items: flex-start;
      height: 120px;
    }

    .summary-text-end {
      text-align: end;
    }

    .summary-email {
      margin-bottom: 8px;
    }

    .summary-associates-price,
    .summary-label-total,
    .summary-associates-name,
    .summary-label-associate,
    .summary-label-membership {
      display: block;
    }

    .summary-label-details-cost-width,
    .details-cost-space {
      margin-left: 0px;
    }

    .summary-gif-section {
      flex-direction: column;
      align-items: flex-start;
    }

    .summary-gift-responsive {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &--first-column {
      padding-right: 0.25rem;
    }

    &--second-column {
      padding-right: 0.25rem;
    }

    &--third-column {
      padding-right: 0.25rem;
    }
  }
}

.none {
  display: none;
}
