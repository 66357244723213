:root {
  --text-base: #606060;
  --text-dark: #4c4c4c;
  --text-darker: #333;
  --text-darkest: #252525;
  --gray: #e5e5e5;
  --gray-disabled: #999;
  --gray-card-border: #ccc;
  --red: #d52b1e;
  --blue: #3d89cc;
  --green: #3bb54a;
  --dark-blue: #2e6799;
  --off-white: #f6f6f6;
  --light-accent: #efefef;
  --med-accent: #bcbcbc;
  --very-light-fill: #fafafa;
  --light-fill: #efefef;
  --white: #fff;
  --black: #000;
}
