.enrollment-page {
  background-color: var(--white);
}
.enrollment-page--gift-id-label {
  color: var(--text-dark);
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  padding-top: 0.5rem;
}
.enrollment-page--gift-id-value {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  padding-left: 0.5rem;
}

