.error-list {
  border: 1px var(--red) solid;
  padding: 0.5rem 0.75rem;
  color: var(--red);
}
.error-list--message {
  margin: 0 0 0.5rem 0;
  color: var(--red);
}
.error-list--message::before {
  content: "";
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  background: transparent url("~/public/images/icons/warning-icon.svg") no-repeat center center;
  background-size: cover;
  position: relative;
  top: 0.125rem;
  margin-right: 0.5rem;
}
.error-list--list {
  color: var(--red);
  margin: 0 0 0.5rem 0;
  padding-left: 1rem;
}
.error-list--list li {
  font-size: 0.875rem;
  line-height: 1.4;
}

