@import "../../styles/dims";

.question-box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  &--indicator {
    opacity: 1;
  }

  &--indicator-image {
    width: 1.25rem;
    height: 0.625rem;
    transform: rotate(0deg) scale(0.5);
    transition: transform 200ms linear;
    transform-origin: 50% 50%;
    outline: 0;
  }

  &--question {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &--question-text {
    color: var(--blue);
    font-size: 1.125rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding-left: 1rem;
    margin: 0;
  }

  &--answer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 0;
    transition: height 200ms linear;
    overflow: hidden;

    a {
      color: var(--blue);
    }

    br {
      margin-bottom: 1rem;
    }
  }

  &--answer-inner {
    opacity: 1;
  }

  &--answer-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 1rem 0 0 2.25rem;
    margin: 0;
  }

  &.active {
    .question-box--question-text {
      color: var(--text-dark);
    }

    .question-box--indicator-image {
      transform: rotate(180deg) scale(0.5);
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    align-items: flex-start;
  }
}