.cost-adjustments {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
  flex-basis: 100%;
  align-self: flex-start;
  text-align: left;
}
.cost-adjustments li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cost-adjustments li.cost-adjustments--total {
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

