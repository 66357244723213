.checkout-page {
  background-color: var(--white);
}
.checkout-page--blurb p {
  margin-bottom: 0;
  padding-right: 0;
}
.checkout-page--secure-icon {
  height: 1.5625rem;
  width: 1.1875rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.125rem;
  margin-right: 0.5rem;
}
.checkout-page--privacy {
  text-align: center;
  margin-bottom: 0.5rem;
}
.checkout-page--privacy a {
  text-decoration: underline;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--text-dark);
}
.checkout-page input[type=number]::-webkit-inner-spin-button,
.checkout-page input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.checkout-page input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (min-width: 48rem) {
  .checkout-page .hero-no-image .hero--inner {
    padding-top: 3.75rem;
    padding-bottom: 1.875rem;
  }
}

