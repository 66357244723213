.contact-info-form {
  width: 100%;
}
.contact-info-form--form-group {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.contact-info-form--form-group .text-input:first-child {
  margin-right: 0.5rem;
}
.contact-info-form--form-group .text-input:last-child {
  margin-left: 0.5rem;
}
.contact-info-form--group-email {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.contact-info-form--group-recipient {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.contact-info-form--recipient-first-name {
  flex-basis: 85%;
}
.contact-info-form--recipient-middle-inital {
  flex-basis: 15%;
}
.contact-info-form--send-date .text-input--input-wrapper {
  width: 10rem;
}
.contact-info-form .text-input--date {
  background-color: var(--very-light-fill);
  color: var(--text-darker);
}
.contact-info-form--btns {
  width: 100%;
  max-width: 37.5rem;
  margin: 2rem auto 0 auto;
}
.contact-info-form--btns .btn-block {
  max-width: none;
}
.contact-info-form--btns.contact-info-form--btns-multi {
  display: flex;
  flex-direction: column;
}
.contact-info-form--btns.contact-info-form--btns-multi .btn {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 48rem) {
  .contact-info-form--btns.contact-info-form--btns-multi .btn {
    margin: 0 auto 0.625rem;
  }
}
.contact-info-form--btns .contact-info-form--cacncel {
  order: 1;
}
.contact-info-form--btns .contact-info-form--submit {
  order: 0;
}
.contact-info-form--btns.contact-info-form--btns-single {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.contact-info-form--btns.contact-info-form--btns-single .btn {
  margin-top: 1rem;
}
.contact-info-form--btns.contact-info-form--btns-single .btn:first-child {
  margin-top: 0;
}
.contact-info-form--btns-pad {
  padding-top: 1.5rem;
}
@media screen and (min-width: 48rem) {
  .contact-info-form--send-date {
    width: auto;
    display: inline-block;
    vertical-align: bottom;
  }
  .contact-info-form--submit, .contact-info-form--cacncel {
    width: calc(50% - 0.5rem);
  }
  .contact-info-form--group-email {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .contact-info-form--group-recipient {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .contact-info-form--recipient-email {
    width: calc(50% - 0.5rem);
    padding-right: 0.25rem;
  }
  .contact-info-form--recipient-email-confirm {
    width: calc(50% - 0.5rem);
    padding-left: 0.25rem;
  }
  .contact-info-form--recipient-first-name {
    flex-basis: 90%;
  }
  .contact-info-form--recipient-middle-inital {
    flex-basis: 11%;
  }
  .contact-info-form--recipient-last-name {
    flex-basis: 100%;
    margin-left: 1rem;
  }
  .contact-info-form--btns.contact-info-form--btns-multi {
    flex-direction: row;
    align-self: flex-end;
    margin-right: 0;
    margin-top: 3rem;
  }
  .contact-info-form--btns.contact-info-form--btns-multi .btn {
    margin-bottom: 0.625rem;
  }
  .contact-info-form--btns.contact-info-form--btns-multi .btn:first-child {
    margin-right: 0.5rem;
  }
  .contact-info-form--btns.contact-info-form--btns-multi .btn:last-child {
    margin-left: 0.5rem;
  }
}

