.toolbar {
  background: var(--white);
  border-bottom: 1px var(--med-accent) solid;
  height: 3.125rem;
  text-align: center;
  position: relative;
  z-index: 2;
}
.toolbar--inner {
  position: relative;
}
.toolbar--back {
  position: absolute;
  top: 0.9375rem;
  left: 1.25rem;
  width: 0.6875rem;
  height: 1.25rem;
  background: transparent url("~/public/images/icons/back-icon.svg") no-repeat center center;
}
.toolbar--logo {
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin: 0 auto;
  text-align: center;
  width: auto;
}
.toolbar--logo-img {
  width: 3.5rem;
  height: 2.125rem;
  margin: 0.5rem;
}
.toolbar--cart {
  position: absolute;
  top: 0.9375rem;
  right: 1.25rem;
}
.toolbar--cart-img {
  width: 1.625rem;
  height: 1.25rem;
}
.toolbar--count {
  background: var(--red);
  border-radius: 0.4375rem;
  color: var(--white);
  display: block;
  font-size: 0.625rem;
  line-height: 1.1;
  height: 0.875rem;
  min-width: 0.875rem;
  padding: 0.075rem 0.125rem;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  text-align: center;
}
.toolbar a {
  cursor: pointer;
}

body.edit-mode .toolbar--back {
  display: none;
}

