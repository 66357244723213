@import "../../styles/dims";

.promo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;

  &--bar {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--promo-section {
    color: #757575;
    font-weight: 400;
    width: fit-content;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
  }

  &--summary {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--promo-code {
    padding-right: 0;
    padding-top: 0;
    max-width: 240px;

    input {
      text-transform: uppercase;
    }
  }

  &--promo {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &--promo-code-code {
    flex: auto;
  }

  &--promo-code-message {
    flex: initial;
    width: 245px;
    margin-left: 2rem;
  }

  &--promo-code-btn {
    flex: initial;
    display: flex;
    justify-content: flex-end;
  }

  &--btn {
    margin-top: 1.5rem;
  }

  &--message {
    margin: 0 0 0.5rem 0;
    color: #3bb54a;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background: transparent url('~/public/images/icons/dot.svg') no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0.125rem;
      left: -1.7rem;
      margin-right: 0.5rem;
    }
  }

  &--title-message {
    margin: 0 0 0.5rem 0;
    color: #3bb54a;
    position: relative;
    font-weight: bold;
    padding-bottom: 10px;

    &::before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background: transparent url('~/public/images/icons/check2.svg') no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0.125rem;
      left: -1.7rem;
      margin-right: 0.5rem;
    }
  }

  &--error-message {
    margin: 0 0 0.5rem 0;
    color: #d52b1e;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background: transparent url('~/public/images/icons/dot-red.svg') no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0.125rem;
      left: -1.7rem;
      margin-right: 0.5rem;
    }
  }

  &--error-title-message {
    margin: 0 0 0.5rem 0;
    color: #d52b1e;
    position: relative;
    font-weight: bold;
    padding-bottom: 10px;

    &::before {
      content: '';
      display: inline-block;
      height: 1rem;
      width: 1rem;
      background: transparent url('~/public/images/icons/exclamation.svg') no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0.125rem;
      left: -1.7rem;
      margin-right: 0.5rem;
    }
  }

  @media screen and (max-width: $breakpoint-main) {
    &--promo-code-message {
      order: 3;
    }

    &--promo-code {
      max-width: 150px;
    }
  }

  p {
    margin: 0;

    &.promo--message {
      font-size: 0.875rem;
    }

    &.promo--title-message {
      font-size: 0.875rem;
    }

    &.promo--error-message {
      font-size: 0.875rem;
    }

    &.promo--error-title-message {
      font-size: 0.875rem;
    }
  }
}
