.review-enrollment-page {
  background-color: var(--white);
}
.review-enrollment-page--submit {
  margin-top: 3.25rem;
}
.review-enrollment-page--terms {
  font-size: 0.875rem;
  color: var(--text-base);
  margin-top: 2rem;
}
.review-enrollment-page--terms-link {
  color: var(--blue);
}

