p,
address {
  color: var(--text-dark);
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  &.warning {
    color: var(--red);
    font-size: 0.75rem;
    margin: 0 0 0.5rem 0;
  }

  &.required-label {
    color: var(--red);
    font-size: 0.75rem;
    margin: 0 0 0.5rem 0;
    text-align: right;
  }
}

a:focus {
  outline: 1px var(--blue) dotted;
}

address {
  margin: 1rem 0;
}

h3,
h2.section-heading {
  color: var(--text-darker);
  font-size: 1.625rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

h4 {
  color: var(--text-dark);
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.pre-line {
  white-space: pre-line;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-initialcase {
  text-transform: initial;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalcase {
  text-transform: capitalize;
}

.summary-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase;
  color: var(--text-darker);
}

.summary-value {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  // line-height: 2;
  margin: 0 0 1rem 0;
  white-space: pre-line;
  color: var(--text-darker);
  word-break: break-word;

  &-grey {
    color: #757575;
    word-break: break-word;
  }
}

.summary-multivalue {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  color: var(--text-dark);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin: 0 0 0.75rem 0;
  line-height: 1.4;
  width: 100%;

  &--label {
    order: 0;
    font-size: 0.875rem;
  }

  &--value {
    order: 1;
    font-size: 1rem;
  }
}

.summary-group:not(:empty) {
  padding: 0 0 1rem 0;
}

.warning {
  color: var(--red);
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.ql-editor {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--text-darker);
}

.ql-editor ol li {
  font-size: 1rem !important;
}

.ql-editor ul li {
  font-size: 1rem !important;
}

.ql-editor h1 {
  font-size: 1.375rem !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
}

.ql-editor .ql-size-small {
  font-size: 0.8125rem !important;
}