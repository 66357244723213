/* Imports */
/*
  Fonts:
  fonts.googleapis.com/css?family=Roboto:300,400,500,700"
  fonts.googleapis.com/css?family=Lato:300,400,700,900"
*/
:root {
  --text-base: #606060;
  --text-dark: #4c4c4c;
  --text-darker: #333;
  --text-darkest: #252525;
  --gray: #e5e5e5;
  --gray-disabled: #999;
  --gray-card-border: #ccc;
  --red: #d52b1e;
  --blue: #3d89cc;
  --green: #3bb54a;
  --dark-blue: #2e6799;
  --off-white: #f6f6f6;
  --light-accent: #efefef;
  --med-accent: #bcbcbc;
  --very-light-fill: #fafafa;
  --light-fill: #efefef;
  --white: #fff;
  --black: #000;
}

p,
address {
  color: var(--text-dark);
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
p.warning,
address.warning {
  color: var(--red);
  font-size: 0.75rem;
  margin: 0 0 0.5rem 0;
}
p.required-label,
address.required-label {
  color: var(--red);
  font-size: 0.75rem;
  margin: 0 0 0.5rem 0;
  text-align: right;
}

a:focus {
  outline: 1px var(--blue) dotted;
}

address {
  margin: 1rem 0;
}

h3,
h2.section-heading {
  color: var(--text-darker);
  font-size: 1.625rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

h4 {
  color: var(--text-dark);
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.pre-line {
  white-space: pre-line;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-initialcase {
  text-transform: initial;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalcase {
  text-transform: capitalize;
}

.summary-label {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.4;
  margin: 0;
  text-transform: uppercase;
  color: var(--text-darker);
}

.summary-value {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  white-space: pre-line;
  color: var(--text-darker);
  word-break: break-word;
}
.summary-value-grey {
  color: #757575;
  word-break: break-word;
}

.summary-multivalue {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  color: var(--text-dark);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin: 0 0 0.75rem 0;
  line-height: 1.4;
  width: 100%;
}
.summary-multivalue--label {
  order: 0;
  font-size: 0.875rem;
}
.summary-multivalue--value {
  order: 1;
  font-size: 1rem;
}

.summary-group:not(:empty) {
  padding: 0 0 1rem 0;
}

.warning {
  color: var(--red);
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.ql-editor {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--text-darker);
}

.ql-editor ol li {
  font-size: 1rem !important;
}

.ql-editor ul li {
  font-size: 1rem !important;
}

.ql-editor h1 {
  font-size: 1.375rem !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
}

.ql-editor .ql-size-small {
  font-size: 0.8125rem !important;
}

.Cal__Container__root {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}

.Cal__MonthList__root > div > div:first-child > .Cal__Month__rows > .Cal__Month__partial:first-child {
  background-color: #e0e0e0;
}
.Cal__MonthList__root > div > div:last-child > .Cal__Month__rows > .Cal__Month__partial:last-of-type {
  background-color: #e0e0e0;
}

.Cal__Month__rows {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(255, 255, 255, 0)), to(rgba(0, 0, 0, 0.025))) !important;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.025) 100%) !important;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.025) 100%) !important;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.025) 100%) !important;
}

.Cal__Month__row:first-child li.Cal__Day__disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
}

.Cal__Day__root.Cal__Day__today::before {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
  border: 1px var(--light-fill) solid !important;
  color: var(--med-accent) !important;
  background: transparent !important;
}
.Cal__Day__root.Cal__Day__selected {
  background: transparent !important;
  color: transparent !important;
}
.Cal__Day__root.Cal__Day__selected > span {
  visibility: hidden;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
  border: 1px var(--blue) solid !important;
  background: transparent !important;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection > span {
  color: var(--blue);
}
.Cal__Day__root.Cal__Day__disabled {
  background-color: #e0e0e0;
  border: none;
}
.Cal__Day__root:hover::before {
  background: transparent !important;
  box-shadow: none !important;
  display: none !important;
}

.Cal__Weekdays__root {
  background-color: var(--blue) !important;
}

.Cal__Weekdays__day {
  cursor: default;
}

.Cal__Today__root.Cal__Today__show {
  display: none !important;
}

/* Base */
html {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  min-height: 100vh;
  width: 100%;
}

#root {
  min-height: 100vh;
  width: 100%;
}

* {
  box-sizing: border-box;
}

*::selection {
  background-color: var(--blue);
  color: var(--white);
}

a {
  cursor: pointer;
}

.hr {
  background-color: var(--gray-card-border);
  border: none;
  display: block;
  height: 1px;
  margin: 20px 0;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

