@import "../../styles/dims";

.review-order-page {
  background-color: var(--white);

  &--blurb {
    padding-right: 0;
  }

  &--total {
    text-align: right;

    p {
      font-size: 1.125rem;
      margin: 0;
    }
  }

  &--memberships {
    margin-bottom: 1px;
  }

  &--summary {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 40px;
    justify-content: space-evenly;
  }

  &--terms {
    font-size: 0.875rem;
    color: var(--text-base);
  }

  &--terms-link {
    color: var(--blue);
  }

  &--total-value {
    font-weight: 700;
    font-size: 1rem;
    color: var(--text-darker);
  }

  &--bottom-link {
    text-align: center;
    margin-bottom: 0.5rem;

    a {
      text-decoration: underline;
      font-size: 0.875rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: var(--text-dark);
    }
  }

  &--back-to-cart {
    //margin: 1rem 0;

    a {
      color: var(--red);
    }
  }

  &--privacy {
    margin: 0;
  }

  &--footer--summary {
    flex-direction: row-reverse;
  }

  .payment-details {
    margin-bottom: 1px;
  }

  .error-list {
    margin-top: 1rem;
  }

  .total {
    margin-bottom: 1rem;
  }

  .btn-block {
    margin-bottom: 0.625rem;
    border-radius: 5px;
  }

  @media screen and (min-width: $breakpoint-main) {
    &--summary {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-evenly;

      .review-order-page--submit {
        // order: 1;
        margin: 0 0.25rem 1rem 0;
      }

      .review-order-page--back-to-cart {
        // order: 0;
        margin: 0 0 1rem 0.25rem;
      }
    }
  }
}

@keyframes react-overlay-loader-spinner {
  to {
    transform: rotate(360deg);
  }
}
