@import "../../styles/dims";

.icon-text-box {
  // border-top: 1px var(--light-fill) solid;
  padding: 0.6rem 0;
  font-size: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &--icon-wrapper {
    flex-grow: 1;
    vertical-align: top;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 2.1875rem;
    border: 1px var(--text-dark) solid;
  }

  &--icon-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  &--icon {
    display: inline-block;
    vertical-align: top;
    max-width: 2.1875rem;
    max-height: 2.1875rem;
  }

  &.no-outline {
    .icon-text-box--icon-wrapper {
      border: 0;
    }

    .icon-text-box--icon {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--text-wrapper {
    flex-grow: 4;
    width: calc(100% - 4.375rem);
    padding-left: 1rem;
  }

  &--title {
    color: var(--blue);
    font-size: 1.125rem;
    margin-bottom: 0.375rem;
    text-transform: uppercase;
  }

  &--text {
    //font-size: 1rem;
    margin: 0;
  }

  .icon-text-box--title + .icon-text-box--text {
    padding-right: 0.5rem;
  }

  &:first-of-type {
    // border-top: 0;
    padding-top: 0;
  }

  @media screen and (min-width: $breakpoint-main) {
    &.no-outline {
      .icon-text-box--icon-wrapper {
        width: 6rem;
      }
    }
  }
}
