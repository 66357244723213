.card-membership-type {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  border: 1px solid var(--gray-card-border);
  min-height: 8.125rem;
}
.card-membership-type--name {
  order: 0;
  flex-grow: 2;
  font-size: 1.625rem;
  color: var(--text-darker);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  flex-basis: 70%;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-membership-type--description {
  padding: 0 !important;
}
.card-membership-type--description p:last-of-type {
  display: inline;
}
.card-membership-type--prices {
  float: right;
  flex-grow: 1;
  text-align: right;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--text-dark);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  flex-basis: 30%;
  max-width: 35%;
  white-space: nowrap;
}
.card-membership-type--cost {
  order: 1;
  float: right;
  flex-grow: 1;
  text-align: right;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--text-dark);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-top: 0.7rem;
  white-space: nowrap;
}
.card-membership-type--cost strong {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 400;
}
.card-membership-type--enrollment {
  margin-top: 1rem;
  float: right;
  flex-grow: 1;
  text-align: right;
  line-height: 1.25rem;
  color: var(--text-dark);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  flex-basis: 30%;
  max-width: 30%;
  font-size: 1rem;
  white-space: nowrap;
}
.card-membership-type--enrollment strong {
  margin-left: 5px;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
}
.card-membership-type--details {
  float: left;
  flex-grow: 2;
  overflow: hidden;
  flex-basis: 70%;
  max-width: 65%;
  align-self: flex-start;
  max-height: max-content;
}
.card-membership-type--details p {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
.card-membership-type--learn-more {
  margin-left: 1rem;
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.card-membership-type--select {
  margin-top: 2rem;
  order: 2;
  flex-grow: 1;
  text-align: right;
  align-self: flex-end;
}
.card-membership-type--fee {
  text-align: left;
  margin-top: 0.25rem;
}
.card-membership-type--fee:first-child {
  margin-top: 0;
}
.card-membership-type--adj {
  font-size: 0.75rem;
  margin: 0;
}
.card-membership-type--adj-description {
  font-size: 0.75rem;
  margin: 0;
}
@media screen and (max-width: 48rem) {
  .card-membership-type--details {
    padding-bottom: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
  .card-membership-type--prices {
    flex-basis: 100%;
    max-width: 100%;
  }
  .card-membership-type--cost {
    max-width: 100%;
    flex-basis: 100%;
    float: left;
  }
  .card-membership-type--enrollment {
    margin-top: 0;
    float: left;
    max-width: 100%;
    flex-basis: 100%;
  }
  .card-membership-type--select {
    margin-top: 0;
    align-self: flex-end;
  }
  .card-membership-type #prices {
    float: left;
  }
}

.fill {
  order: 4;
  max-width: 70%;
  flex-basis: 70%;
}

