.error-modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  // filter: blur(5px);
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;

  &--close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 0.75rem;
    color: var(--text-darkest);
  }

  &--icon {
    width: 1rem;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  &--content {
    background: var(--very-light-fill);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    height: auto;
    min-height: 15rem;
    max-width: 30rem;
    padding: 1.5rem 1rem;
    position: relative;
    text-align: center;
    width: 90vw;
  }

  &--title {
    color: var(--black);
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  &--message {
    color: var(--black);
    font-size: 1rem;
    margin-top: 1rem;
    white-space: pre-line;
  }

  &--buttons {
    button {
      margin-top: 0.75rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
