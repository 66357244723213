@import "../../styles/dims";

.payment-details {
  display: block;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: flex-start;
  // align-items: flex-end;

  &--btn {
    left: 300px;
    bottom: 54px;

    @media screen and (max-width: $breakpoint-main) {
      position: relative;
      left: unset;
      bottom: unset;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: flex-start;
    // align-items: flex-end;
    min-height: 100px;

    &--wrapper {
      border: 1px solid var(--gray-card-border);
      width: 100%;
    }

    &--full {
      flex-basis: 100%;
    }
  }

  .btn-primary-sm {
    background-color: #3d89dd;
    border-radius: 5px;
    height: 42px;
    width: 66px;
    border: none;
    color: white;
    font-size: 16px;
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-center {
    text-align: center;
  }
}