@import "../../styles/dims";

.maintenance-page {
  background-color: var(--white);
  padding-bottom: 0;

  .hero {
    .hero--title {
      padding-top: 4.75rem;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    .hero {
      .hero--title {
        padding-top: 20%;
      }
    }

    .maintenance-page--blurb {
      text-align: justify;
    }

    .maintenance-page--info {
      padding-bottom: 2rem;
      padding-top: 2rem;
      margin-top: 1rem;
    }

    h3 {
      text-align: center;
    }

    .icon-text-box {
      width: 33.333%;
      display: inline-block;
      vertical-align: top;
      border: 0;
      padding-top: 0;
      text-align: center;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .icon-text-box--icon-wrapper {
        margin: 0 auto 1rem auto;
      }

      .icon-text-box--text-wrapper {
        padding: 0;
        margin: 0 auto;
      }
    }
  }
}