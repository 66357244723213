.contact-info-page {
  background-color: var(--white);
}
.contact-info-page--blurb p {
  margin: 0;
  white-space: pre-line;
}
@media screen and (min-width: 48rem) {
  .contact-info-page {
    background-color: var(--white);
  }
  .contact-info-page--form .card {
    padding: 0 0 3.25rem 0;
    margin-left: 0;
    margin-right: 0;
  }
  .contact-info-page--confirmation {
    display: inline-block;
    padding-left: 2rem;
    max-width: 25rem;
    vertical-align: bottom;
  }
}

.text-justify {
  text-align: justify;
}

