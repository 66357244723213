/* Imports */
@import "styles/fonts";
@import "styles/colors";
@import "styles/typography";
@import "styles/date-picker-theme";

/* Base */
html {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-display: block;
  font-family: $font-roboto;
  font-weight: 300;
  min-height: 100vh;
  width: 100%;
}

#root {
  min-height: 100vh;
  width: 100%;
}

* {
  box-sizing: border-box;
}

*::selection {
  background-color: var(--blue);
  color: var(--white);
}

a {
  cursor: pointer;
}

.hr {
  background-color: var(--gray-card-border);
  border: none;
  display: block;
  height: 1px;
  margin: 20px 0;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}