.membership-additional-benefits {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 0.5rem;
  width: 100%;
}
.membership-additional-benefits--details {
  order: 1;
  flex-grow: 3;
  height: max-content;
}
.membership-additional-benefits--row-info {
  display: inline-block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  width: 100%;
}

