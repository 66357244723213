.privacy-page {
  background-color: var(--white);
}
.privacy-page a {
  color: var(--blue);
  text-decoration: underline;
}
.privacy-page a[href^=tel] {
  text-decoration: none;
  font-weight: 500;
}
.privacy-page li {
  margin-bottom: 0.5rem;
}
.privacy-page h3 {
  margin-top: 1.5rem;
}
.privacy-page h3:first-child {
  margin-top: 0;
}

