.membership-detail-page {
  background-color: var(--white);
  padding-bottom: 0.5rem;
}
.membership-detail-page--blurb p {
  margin: 0;
}
.membership-detail-page--description p {
  margin: 1rem 0 1rem 0;
  font-size: 0.75rem;
}
.membership-detail-page--description p:first-child {
  margin-top: 0;
}
.membership-detail-page--legal h3 {
  font-size: 1.375rem;
  text-align: left;
}
.membership-detail-page--benefits .card {
  flex-direction: column;
}
.membership-detail-page--benefits .icon-text-box {
  border: 0;
}
.membership-detail-page--benefits-label h3 {
  font-size: 1.375rem;
  text-align: left;
}
.membership-detail-page--action {
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.membership-detail-page--action button {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  max-width: 45%;
}
.membership-detail-page--membership-details {
  padding-bottom: 0;
}
.membership-detail-page h3 {
  color: var(--text-darker);
  margin-bottom: 0;
}
@media screen and (max-width: 48rem) {
  .membership-detail-page--blurb {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
  }
  .membership-detail-page--benefits-label h3 {
    text-align: left;
  }
  .membership-detail-page--benefits {
    padding-top: 0;
  }
  .membership-detail-page--benefits .card {
    margin: 0;
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }
  .membership-detail-page--description {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .membership-detail-page--description p {
    text-align: left;
  }
  .membership-detail-page .btn-block {
    margin-left: 0;
    max-width: 300px;
  }
}

.select--button {
  float: left;
  z-index: 1;
}

