.btn {
  --gradient-start: #fefefe;
  --gradient-end: #e7e7e7;
  --border: #d6d6d6;
  background: var(--gradient-start);
  background: -moz-linear-gradient(top, var(--gradient-start) 0%, var(--gradient-end) 100%);
  background: -webkit-linear-gradient(top, var(--gradient-start) 0%, var(--gradient-end) 100%);
  background: linear-gradient(to bottom, var(--gradient-start) 0%, var(--gradient-end) 100%);
  border: 1px var(--border) solid;
  border-radius: 0.2rem;
  color: var(--red);
  display: inline-block;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.4;
  padding: 0.5rem 1rem;
  width: auto;
  height: 2.5rem;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.btn-small {
  font-size: 1rem;
  padding: 0.325rem 0.65rem;
  width: 50pt;
}
.btn-med {
  font-size: 1rem;
  padding: 0.325rem 0.65rem;
}
.btn-large {
  font-size: 1rem;
  padding: 0.325rem 0.65rem;
}
.btn-gift-another {
  padding: 0.325rem 0.65rem;
  width: 100%;
  height: 100px;
  color: var(--blue);
  background: var(--white);
  border: 1px dashed var(--blue);
  font-size: 15pt;
}
.btn-block {
  display: block;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 48rem) {
  .btn-block {
    max-width: 300px;
    height: 42px;
  }
}
.btn-inline {
  display: inline-block;
  width: auto;
}
.btn-alt {
  color: var(--blue);
}
.btn-action {
  background: var(--red);
  color: var(--white);
  border: none;
}
.btn-blank {
  background: transparent;
  border: 0;
  text-decoration: underline;
}
.btn-alt-simple {
  background: var(--white);
  border: 1px solid var(--blue);
  color: var(--blue);
}
.btn-alt-apply {
  background: var(--white);
  border: 1px solid var(--green);
  color: var(--green);
}
.btn-alt-full {
  background: var(--blue);
  border: 1px solid var(--white);
  color: var(--white);
  border-radius: 5px;
}
.btn-action-simple {
  background: var(--white);
  border: 1px solid var(--red);
  color: var(--red);
}
.btn[disabled] {
  background: var(--gray);
  border: none;
  color: var(--gray-disabled);
  cursor: not-allowed;
  opacity: 0.6;
}
.btn:focus {
  outline: 1px var(--blue) solid;
  outline-offset: 0;
  box-shadow: none;
}

