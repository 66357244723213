@use "sass:math";
@import "../../styles/dims";


.thank-you-page {
  background-color: var(--white);

  .total {
    margin-top: 1.5rem;
  }

  &--order-info {
    p {
      color: var(--text-darker);
      margin: 0;
    }

    &::after {
      content: '';
      border-bottom: 1px var(--white) solid;
      display: block;
      width: calc(100% - 2rem);
      height: 0;
      position: absolute;
      bottom: 0;
    }
  }

  &--blurb {
    h3 {
      margin: 0;
    }
  }

  &--whatsnext {
    h3,
    p {
      margin-bottom: 1.125rem;
    }
  }

  &--memberships {
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 0;
  }

  &--confirmation {
    color: var(--text-dark);
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    padding-top: 0.5rem;
  }

  .alt-section {
    border-bottom: 2px solid var(--white);
  }

  @media screen and (min-width: $breakpoint-main) {
    .card {
      margin: 0;
    }

    &--order-info {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &--whatsnext {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &--content {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

.text-center {
  text-align: center;
}

$brand-success: #02539b;
$loader-size: 6em;
$check-height:  math.div($loader-size, 3);
$check-width: math.div($check-height, 3);
$check-left: ( math.div($loader-size, 10) +  math.div($loader-size, 10));
$check-thickness: 8px;
$check-color: $brand-success;

.circle-loader {
  margin-bottom: math.div($loader-size, 2);
  border: 8px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw::after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &::after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height + 0.5;
    position: absolute;
  }
}