.recipient-thank-you-page {
  background-color: var(--white);
}
.recipient-thank-you-page--next {
  text-align: center;
}
.recipient-thank-you-page--link {
  color: var(--blue);
  text-decoration: underline;
}
.recipient-thank-you-page--support {
  text-align: center;
}
@media screen and (min-width: 48rem) {
  .recipient-thank-you-page--next {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .recipient-thank-you-page--questions {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.btn-med {
  padding: 0.6rem 1rem;
}

