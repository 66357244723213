.review-order-page {
  background-color: var(--white);
}
.review-order-page--blurb {
  padding-right: 0;
}
.review-order-page--total {
  text-align: right;
}
.review-order-page--total p {
  font-size: 1.125rem;
  margin: 0;
}
.review-order-page--memberships {
  margin-bottom: 1px;
}
.review-order-page--summary {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 40px;
  justify-content: space-evenly;
}
.review-order-page--terms {
  font-size: 0.875rem;
  color: var(--text-base);
}
.review-order-page--terms-link {
  color: var(--blue);
}
.review-order-page--total-value {
  font-weight: 700;
  font-size: 1rem;
  color: var(--text-darker);
}
.review-order-page--bottom-link {
  text-align: center;
  margin-bottom: 0.5rem;
}
.review-order-page--bottom-link a {
  text-decoration: underline;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--text-dark);
}
.review-order-page--back-to-cart a {
  color: var(--red);
}
.review-order-page--privacy {
  margin: 0;
}
.review-order-page--footer--summary {
  flex-direction: row-reverse;
}
.review-order-page .payment-details {
  margin-bottom: 1px;
}
.review-order-page .error-list {
  margin-top: 1rem;
}
.review-order-page .total {
  margin-bottom: 1rem;
}
.review-order-page .btn-block {
  margin-bottom: 0.625rem;
  border-radius: 5px;
}
@media screen and (min-width: 48rem) {
  .review-order-page--summary {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .review-order-page--summary .review-order-page--submit {
    margin: 0 0.25rem 1rem 0;
  }
  .review-order-page--summary .review-order-page--back-to-cart {
    margin: 0 0 1rem 0.25rem;
  }
}

@keyframes react-overlay-loader-spinner {
  to {
    transform: rotate(360deg);
  }
}

