.membership-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.membership-details .card {
  display: flex;
  flex-basis: auto;
  margin-top: 0.5rem;
  text-align: center;
}
.membership-details .card:first-child {
  margin-top: 0;
}
.membership-details--cost {
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membership-details--cost-price {
  font-size: 0.8125rem;
  line-height: 1.625rem;
  position: relative;
  top: 0.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-align: left;
  float: left;
}
.membership-details--cost-price strong {
  font-size: 1.3em;
  font-weight: 100;
}
.membership-details--cost-enrollment {
  line-height: 1.625rem;
  position: relative;
  top: 0.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  float: left;
}
.membership-details--cost-enrollment strong {
  margin-left: 5px;
  font-size: 1.2rem;
  font-weight: 100;
}
.membership-details--exception {
  width: 100%;
  order: 1;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
.membership-details--towing {
  width: 100%;
  flex-direction: column;
  order: 0;
}
.membership-details--service {
  margin-right: 0.25rem;
  width: calc(50% - 0.25rem);
  flex-direction: column;
  order: 2;
}
.membership-details--coverage {
  margin-left: 0.25rem;
  width: calc(50% - 0.25rem);
  flex-direction: column;
  order: 3;
}
@media screen and (min-width: 48rem) {
  .membership-details {
    padding-left: 0;
    padding-right: 0;
  }
  .membership-details--towing {
    margin-right: 0.25rem;
    width: auto;
    flex-grow: 1;
    order: 0;
  }
  .membership-details--service {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    width: auto;
    flex-grow: 1;
    order: 1;
  }
  .membership-details--coverage {
    margin-left: 0.25rem;
    width: auto;
    flex-grow: 1;
    order: 2;
  }
  .membership-details--exception {
    order: 3;
    text-align: left;
    margin-bottom: 0;
  }
}

