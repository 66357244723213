.icon-text-box {
  padding: 0.6rem 0;
  font-size: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.icon-text-box--icon-wrapper {
  flex-grow: 1;
  vertical-align: top;
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 2.1875rem;
  border: 1px var(--text-dark) solid;
}
.icon-text-box--icon-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.icon-text-box--icon {
  display: inline-block;
  vertical-align: top;
  max-width: 2.1875rem;
  max-height: 2.1875rem;
}
.icon-text-box.no-outline .icon-text-box--icon-wrapper {
  border: 0;
}
.icon-text-box.no-outline .icon-text-box--icon {
  max-width: 100%;
  max-height: 100%;
}
.icon-text-box--text-wrapper {
  flex-grow: 4;
  width: calc(100% - 4.375rem);
  padding-left: 1rem;
}
.icon-text-box--title {
  color: var(--blue);
  font-size: 1.125rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
}
.icon-text-box--text {
  margin: 0;
}
.icon-text-box .icon-text-box--title + .icon-text-box--text {
  padding-right: 0.5rem;
}
.icon-text-box:first-of-type {
  padding-top: 0;
}
@media screen and (min-width: 48rem) {
  .icon-text-box.no-outline .icon-text-box--icon-wrapper {
    width: 6rem;
  }
}

