.app {
  background-color: var(--light-fill);
  text-align: left;
  min-height: 100vh;
}
.app--centered {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}
.app--inset {
  width: 100%;
  max-width: 37.5rem;
  margin-left: auto;
  margin-right: auto;
}

.page {
  min-height: calc(100vh - 7.25rem);
}

