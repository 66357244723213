@import "../../styles/dims";

$padding-vertical: 1rem;
$padding-horizonal: 1.25rem;

.card {
  background: #fff;
  display: flex;
  padding: $padding-vertical $padding-horizonal;
  border: 1px solid var(--gray-card-border);

  &-padding-all {
    padding: $padding-vertical $padding-horizonal;
  }

  &-padding-no-top {
    padding: 0 $padding-horizonal $padding-vertical $padding-horizonal;
  }

  &-padding-no-bottom {
    padding: $padding-vertical $padding-horizonal 0 $padding-horizonal;
  }

  &-padding-vertical {
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
    padding-left: 0;
    padding-right: 0;
  }

  &-padding-horizontal {
    padding-left: $padding-horizonal;
    padding-right: $padding-horizonal;
    padding-top: 0;
    padding-bottom: 0;
  }

  &-padding-top {
    padding-top: $padding-vertical;
  }

  &-padding-bottom {
    padding-bottom: $padding-vertical;
  }

  &-padding-left {
    padding-left: $padding-horizonal;
  }

  &-padding-right {
    padding-right: $padding-horizonal;
  }

  &-padding-none {
    padding: 0;
  }

  &.card-vertical {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  @media screen and (min-width: $breakpoint-main) {
    padding: $tablet-padding-vertical $tablet-padding-horizonal;

    &-padding-all {
      padding: $tablet-padding-vertical $tablet-padding-horizonal;
    }

    &-padding-no-top {
      padding: 0 $tablet-padding-horizonal $tablet-padding-vertical $tablet-padding-horizonal;
    }

    &-padding-no-bottom {
      padding: $tablet-padding-vertical $tablet-padding-horizonal 0 $tablet-padding-horizonal;
    }

    &-padding-vertical {
      padding-top: $tablet-padding-vertical;
      padding-bottom: $tablet-padding-vertical;
    }

    &-padding-horizontal {
      padding-left: $tablet-padding-horizonal;
      padding-right: $tablet-padding-horizonal;
    }

    &-padding-top {
      padding-top: $tablet-padding-vertical;
    }

    &-padding-bottom {
      padding-bottom: $tablet-padding-vertical;
    }

    &-padding-left {
      padding-left: $tablet-padding-horizonal;
    }

    &-padding-right {
      padding-right: $tablet-padding-horizonal;
    }

    &-padding-none {
      padding: 0;
    }
  }
}
