@import "../../styles/dims";

.contact-info-page {
  background-color: var(--white);

  &--blurb {
    p {
      margin: 0;
      white-space: pre-line;
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    background-color: var(--white);

    &--form {
      .card {
        padding: 0 0 3.25rem 0;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &--confirmation {
      display: inline-block;
      padding-left: 2rem;
      max-width: 25rem;
      vertical-align: bottom;
    }
  }
}

.text-justify {
  text-align: justify;
}