@import "../../styles/dims";

.cart-page {
  background-color: var(--white);

  .total {
    margin-bottom: 2rem;
  }

  .promo {
    margin-bottom: 2rem;
  }

  &--form {
    width: 100%;
  }

  &--submit {
    margin-bottom: 0.625rem;
  }

  &--add-another {
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
  }

  &--empty-title {
    margin: 1rem 0;
  }

  &--empty-text {
    margin: 1rem 0;
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  &--empty-button {
    margin: 3.25rem 0 1rem 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
  }

  &--buttons-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
  }

  @media screen and (min-width: $breakpoint-main) {
    background-color: var(--white);

    &--buttons-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row;
    }

    &--buttons {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      max-width: 37.5rem;
      width: 100%;

      .btn-block {
        max-width: none;
      }
    }

    .cart-page--submit {
      width: calc(50% - 0.5rem);
      // order: 1;
    }
  }
}

.section--title {
  font-size: 1rem;
}

.btn-med {
  padding: 0.6rem 1rem;
}