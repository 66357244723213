.membership-information-page {
  background-color: var(--white);
}
.membership-information-page--membership-benefits {
  padding-bottom: 0;
}
.membership-information-page--membership-additional-benefits {
  padding-bottom: 0;
}
@media screen and (max-width: 48rem) {
  .membership-information-page--blurb {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
  }
  .membership-information-page--benefits-title h3 {
    text-align: left;
  }
}

.section--title {
  font-size: 1rem;
}

