.enrollment-form--form-group {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.enrollment-form--form-group .text-input:first-child {
  margin-right: 0.5rem;
}
.enrollment-form--form-group .text-input:last-child {
  margin-left: 0.5rem;
}
.enrollment-form--content .text-input:first-child {
  margin-right: 0.5rem;
}
.enrollment-form--content .text-input:last-child {
  margin-left: 0;
}
@media screen and (min-width: 48rem) {
  .enrollment-form--content {
    display: flex;
  }
  .enrollment-form--content .text-input:first-child {
    margin-right: 0.5rem;
  }
  .enrollment-form--content .text-input:last-child {
    margin-left: 0.5rem;
  }
  .enrollment-form--content .text-input--description {
    text-align: justify;
  }
  .enrollment-form--city {
    margin-right: 0.5rem !important;
  }
}
.enrollment-form--submit {
  margin-top: 3.25rem;
}
.enrollment-form--first-name {
  flex-basis: 90%;
}
.enrollment-form--last-name {
  flex-basis: 100%;
}
.enrollment-form--middle-initial {
  margin-right: 0;
  flex-basis: 15%;
}
.enrollment-form--address-1 {
  flex-basis: 50%;
  margin-right: 0 !important;
}
.enrollment-form--address-2 {
  flex-basis: 50%;
}
.enrollment-form--phone-number {
  flex-basis: 50%;
  margin-right: 0 !important;
}
.enrollment-form--birth-year {
  flex-basis: 15%;
  margin-left: 0;
}
.enrollment-form--city {
  flex-basis: 50%;
  margin-right: 0.5rem;
}
.enrollment-form--state {
  flex-basis: 30%;
}
.enrollment-form--zip-code {
  flex-basis: 20%;
}
.enrollment-form--membership-number {
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-basis: 49%;
}
.enrollment-form--btns.enrollment-form--btns-multi {
  margin-top: 3.25rem;
}
.enrollment-form--btns.enrollment-form--btns-multi .enrollment-form--cacncel {
  margin-top: 1rem;
}
.enrollment-form .text-input--description {
  text-align: justify;
}

