@import "../../styles/dims";

.location-select-page {
  background-color: var(--white);

  &--form {
    //
  }

  &--submit {
    margin-top: 2rem;
  }

  .customer-location-input {
    margin-bottom: 1rem;
  }

  &--help {
    a {
      color: var(--blue);
    }
  }
}

#stateProvinceState:focus {
  width: 568px;
  position: absolute;
  z-index: 1;
}

#no-results {
  margin-top: 3.3rem;
}

.bold {
  font-weight: bold;
}