// breakpoints
$breakpoint-main: 48rem;
$breakpoint-small: 25rem;

// max-widths
$non-bleed-max-width: 48rem; // max-width of non bleed layout items
$inset-section-max-width: 37.5rem;

// paddings
$tablet-padding-vertical: 1.125rem;
$tablet-padding-horizonal: 1.25rem;