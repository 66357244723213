.checkout-form--group-email {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.checkout-form--group-customer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.checkout-form--group-card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.checkout-form--submit._margin-top-none {
  margin-top: 0;
}
.checkout-form--expiration-date {
  width: 7rem;
}
.checkout-form--expiration-date .text-input--label {
  overflow: visible;
  white-space: nowrap;
}
.checkout-form--expiration-month {
  width: 3rem;
}
.checkout-form--expiration-month .text-input--label {
  overflow: visible;
  white-space: nowrap;
}
.checkout-form--security-code {
  width: 3.5rem;
  margin-left: 1.5rem;
}
.checkout-form--zip-code {
  width: 7rem;
}
.checkout-form--form-card {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.checkout-form--payment-methods {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.checkout-form--payment-methods img {
  margin-right: 0.3rem;
  margin-top: 1rem;
}
.checkout-form--payment-methods img:last-child {
  margin-right: 0;
}
.checkout-form--form-group {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.checkout-form--form-group .text-input:first-child {
  margin-right: 2rem;
}
.checkout-form--form-group-names {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.checkout-form--form-group-names .text-input:first-child {
  margin-right: 0.5rem;
}
.checkout-form--form-group-names .text-input:last-child {
  margin-left: 0.5rem;
}
.checkout-form--customer-first-name {
  flex-basis: 85%;
  padding-top: 0rem !important;
}
.checkout-form--customer-first-name {
  flex-basis: 85%;
  padding-top: 0rem !important;
}
.checkout-form--customer-middle-initial {
  flex-basis: 15%;
  padding-top: 0rem !important;
}
.checkout-form--group-address {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.checkout-form--form-group-state {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.checkout-form--form-group-state .text-input:first-child {
  margin-right: 0.5rem;
}
.checkout-form--form-group-state .text-input:last-child {
  margin-left: 0.5rem;
}
.checkout-form--state {
  flex-basis: 60%;
}
.checkout-form--donor-zip-code {
  flex-basis: 40%;
}
.checkout-form--date-group {
  width: 90%;
}
.checkout-form--btns {
  margin-top: 2.1875rem;
}
.checkout-form--btns.checkout-form--btns-multi {
  display: flex;
  flex-direction: row;
}
.checkout-form--btns.checkout-form--btns-multi .btn:first-child {
  margin-right: 0.5rem;
}
.checkout-form--btns.checkout-form--btns-multi .btn:last-child {
  margin-left: 0.5rem;
}
.checkout-form--btns.checkout-form--btns-single {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.checkout-form--btns.checkout-form--btns-single .btn {
  margin-top: 1rem;
}
.checkout-form--btns.checkout-form--btns-single .btn:first-child {
  margin-top: 0;
}
.checkout-form .checkout-form--expiration-month {
  width: 10%;
}
.checkout-form .checkout-form--expiration-year {
  width: 15%;
}
.checkout-form .checkout-form--security-code {
  width: 30%;
}
.checkout-form .checkout-form--telephone {
  width: 50%;
  padding-right: 0.25rem;
}
.checkout-form .checkout-form--your-email-confirm {
  margin-bottom: 1.5rem;
}
.checkout-form .checkout-form--address {
  padding-top: 0rem !important;
}
.checkout-form .error-list {
  margin-bottom: 1rem;
}
@media screen and (max-width: 48rem) {
  .checkout-form--group-email {
    height: 200px;
  }
}
@media screen and (min-width: 48rem) {
  .checkout-form--date-group {
    width: 50%;
  }
  .checkout-form--group-customer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .checkout-form--group-address {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .checkout-form--group-email {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .checkout-form--group-card {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .checkout-form--your-email {
    width: calc(50% - 0.5rem);
  }
  .checkout-form--your-email-confirm {
    width: calc(50% - 0.5rem);
    padding-left: 0.25rem;
  }
  .checkout-form--customer-first-name {
    flex-basis: 90%;
  }
  .checkout-form--customer-middle-initial {
    flex-basis: 11%;
  }
  .checkout-form--customer-last-name {
    flex-basis: 100%;
    margin-left: 1rem;
    padding-top: 0rem !important;
  }
  .checkout-form--city {
    flex-basis: 100%;
    margin-right: 1rem;
  }
  .checkout-form--customer-state {
    flex-basis: 90%;
  }
  .checkout-form--customer-zip-code {
    flex-basis: 11%;
  }
}
.checkout-form .StripeElement {
  color: var(--text-dark);
  background: var(--very-light-fill);
  border: 1px var(--med-accent) solid;
  border-radius: 1px;
  box-shadow: none !important;
  line-height: 1.2 !important;
  padding: 0.5rem 0.625rem;
}
.checkout-form .StripeElement iframe {
  vertical-align: middle;
}
.checkout-form .StripeElement--invalid {
  border-color: var(--red);
  color: var(--text-dark);
}
.checkout-form .StripeElement--invalid label {
  color: var(--red);
}
.checkout-form input::placeholder {
  color: rgba(170, 183, 196, 0);
}
.checkout-form input:focus,
.checkout-form .StripeElement--focus {
  outline: 1px var(--blue) solid;
  outline-offset: 0;
  box-shadow: none;
}
.checkout-form input[type=radio]:focus {
  outline: none;
}

