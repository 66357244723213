.membership-benefits {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
  width: 100%;
}
.membership-benefits--glance {
  align-items: baseline;
  display: flex;
  flex-grow: 3;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 1;
  margin-top: 0;
}
.membership-benefits--name {
  flex-grow: 2;
  font-size: 1.125rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #757575;
  margin: 0;
}
.membership-benefits--details {
  order: 2;
  flex-grow: 3;
  height: max-content;
}
.membership-benefits--content {
  line-height: 1;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
}
.membership-benefits--group-info {
  display: inline-block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  width: 100%;
}
.membership-benefits--hr-column {
  text-align: center !important;
  margin-left: 3%;
  width: 97%;
  height: 1px;
  background-color: var(--gray-card-border);
  border: none;
  display: block;
}
.membership-benefits--first-column {
  border: 1px solid var(--gray-card-border);
  text-align: center;
  padding: 1% 3%;
}
.membership-benefits--second-column {
  border: 1px solid var(--gray-card-border);
  text-align: center;
  padding: 1% 3%;
}
.membership-benefits--third-column {
  border: 1px solid var(--gray-card-border);
  text-align: center;
  padding: 1% 3%;
}
.membership-benefits--column-value {
  font-size: 3rem;
  font-weight: bold;
  line-height: 2px;
  color: var(--blue);
}
.membership-benefits--column-label {
  color: var(--text-dark);
  display: block;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
}
.membership-benefits--column-label:first-child {
  padding-bottom: 0.75rem;
  padding-top: 0.125rem;
  border-bottom: 1px var(--light-fill) solid;
}
.membership-benefits--column-label:last-child {
  padding-top: 0.75rem;
  border-top: 1px var(--light-fill) solid;
}
@media screen and (min-width: 50px) {
  .membership-benefits--group-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .membership-benefits--first-column {
    width: calc(33% - 0.5rem);
  }
  .membership-benefits--second-column {
    width: calc(33% - 0.5rem);
  }
  .membership-benefits--third-column {
    width: calc(33% - 0.5rem);
  }
  .membership-benefits--column-label {
    font-size: 0.8rem;
  }
  .membership-benefits--column-value {
    font-size: 1.5rem;
  }
}
.membership-benefits.exception .card-membership-stat--value span::after {
  content: " *";
  color: var(--red);
  transform-origin: 50% 0;
  transform: scale(0.75);
  display: inline-block;
}

