.thank-you-page {
  background-color: var(--white);
}
.thank-you-page .total {
  margin-top: 1.5rem;
}
.thank-you-page--order-info p {
  color: var(--text-darker);
  margin: 0;
}
.thank-you-page--order-info::after {
  content: "";
  border-bottom: 1px var(--white) solid;
  display: block;
  width: calc(100% - 2rem);
  height: 0;
  position: absolute;
  bottom: 0;
}
.thank-you-page--blurb h3 {
  margin: 0;
}
.thank-you-page--whatsnext h3,
.thank-you-page--whatsnext p {
  margin-bottom: 1.125rem;
}
.thank-you-page--memberships {
  margin-bottom: 1px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 0;
}
.thank-you-page--confirmation {
  color: var(--text-dark);
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 1rem;
  padding-top: 0.5rem;
}
.thank-you-page .alt-section {
  border-bottom: 2px solid var(--white);
}
@media screen and (min-width: 48rem) {
  .thank-you-page .card {
    margin: 0;
  }
  .thank-you-page--order-info {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .thank-you-page--whatsnext {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .thank-you-page--content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.text-center {
  text-align: center;
}

.circle-loader {
  margin-bottom: 3em;
  border: 8px solid rgba(0, 0, 0, 0.2);
  border-left-color: #02539b;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 6em;
  height: 6em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #02539b;
  transition: border 500ms ease-out;
}

.checkmark.draw::after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark::after {
  opacity: 1;
  height: 2em;
  width: 0.6666666667em;
  transform-origin: left top;
  border-right: 8px solid #02539b;
  border-top: 8px solid #02539b;
  content: "";
  left: 1.2em;
  top: 2.5em;
  position: absolute;
}

