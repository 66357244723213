$disabled-background: #e0e0e0;
$month-gradient-start: rgba(255, 255, 255, 0);
$month-gradient-end: rgba(0, 0, 0, 0.025);

// Calendar wrapper root element
.Cal__Container__root {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}

// Calendar Months wrapper
.Cal__MonthList__root {
  & > div > div {
    &:first-child {
      & > .Cal__Month__rows > .Cal__Month__partial:first-child {
        background-color: $disabled-background;
      }
    }

    &:last-child {
      & > .Cal__Month__rows > .Cal__Month__partial:last-of-type {
        background-color: $disabled-background;
      }
    }

    // .Cal__Month__row:first-child.Cal__Month__partial:first-child li:first-child {
    // 	background: #0d0;
    // }
  }
}

// Month Wrapper
.Cal__Month__rows {
  // background-color: $disabled-background;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, $month-gradient-start), to($month-gradient-end)) !important;
  background: -webkit-linear-gradient(top, $month-gradient-start 50%, $month-gradient-end 100%) !important;
  background: -o-linear-gradient(top, $month-gradient-start 50%, $month-gradient-end 100%) !important;
  background: linear-gradient(to bottom, $month-gradient-start 50%, $month-gradient-end 100%) !important;
}

// Week Row
.Cal__Month__row {
  &:first-child li {
    &.Cal__Day__disabled {
      cursor: not-allowed;
      background-color: $disabled-background;
    }
  }
}

// Day
.Cal__Day__root {
  // border-bottom: 1px rgba(0, 0, 0, 0.05) solid;
  // border-right: 1px rgba(0, 0, 0, 0.05) solid;

  // TODAY
  &.Cal__Day__today {
    &::before {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      border-radius: 0 !important;
      border: 1px var(--light-fill) solid !important;
      color: var(--med-accent) !important;
      background: transparent !important;
    }
  }

  &.Cal__Day__selected {
    background: transparent !important;
    color: transparent !important;

    > span {
      visibility: hidden;
    }

    .Cal__Day__selection {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      border-radius: 0 !important;
      border: 1px var(--blue) solid !important;
      background: transparent !important;

      > span {
        color: var(--blue);
      }
    }
  }

  &.Cal__Day__disabled {
    background-color: $disabled-background;
    border: none;
  }

  &:hover {
    &::before {
      background: transparent !important;
      box-shadow: none !important;
      display: none !important;
    }
  }
}

// Day of week heading wrapper
.Cal__Weekdays__root {
  background-color: var(--blue) !important;
}

// Day of week heading individual
.Cal__Weekdays__day {
  cursor: default;
}

.Cal__Today__root {
  &.Cal__Today__show {
    display: none !important;
  }
}