@import "../../styles/dims";

.membership-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  //padding: 0 0.5rem;

  .card {
    display: flex;
    flex-basis: auto;
    margin-top: 0.5rem;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  &--cost {
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--cost-price {
    font-size: 0.8125rem;
    line-height: 1.625rem;
    position: relative;
    top: 0.25rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-align: left;
    float: left;

    strong {
      font-size: 1.3em;
      font-weight: 100;
    }
  }

  &--cost-enrollment {
    line-height: 1.625rem;
    position: relative;
    top: 0.25rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    float: left;

    strong {
      margin-left: 5px;
      font-size: 1.2rem;
      font-weight: 100;
    }
  }

  &--exception {
    width: 100%;
    order: 1;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  &--towing {
    width: 100%;
    flex-direction: column;
    order: 0;
  }

  &--service {
    margin-right: 0.25rem;
    width: calc(50% - 0.25rem);
    flex-direction: column;
    order: 2;
  }

  &--coverage {
    margin-left: 0.25rem;
    width: calc(50% - 0.25rem);
    flex-direction: column;
    order: 3;
  }

  @media screen and (min-width: $breakpoint-main) {
    padding-left: 0;
    padding-right: 0;

    &--towing {
      margin-right: 0.25rem;
      width: auto;
      flex-grow: 1;
      order: 0;
    }

    &--service {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      width: auto;
      flex-grow: 1;
      order: 1;
    }

    &--coverage {
      margin-left: 0.25rem;
      width: auto;
      flex-grow: 1;
      order: 2;
    }

    &--exception {
      order: 3;
      text-align: left;
      margin-bottom: 0;
    }
  }
}
