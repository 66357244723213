.membership-item {
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
}
.membership-item--recipient {
  flex-grow: 3;
  font-size: 1.125rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--text-darker);
  margin: 0;
  order: 0;
}
.membership-item--plan {
  flex-grow: 2;
  font-size: 1.125rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #757575;
  margin: 0;
  margin-bottom: 0.5rem;
}
.membership-item--plan-discounted {
  flex-grow: 2;
  font-size: 1.125rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #757575;
  margin: 0;
}
.membership-item--net-cost {
  color: #757575;
  flex-grow: 1;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.4;
  text-align: right;
  margin: 0;
}
.membership-item--net-cost-discounted {
  color: #3bb54a;
  flex-grow: 1;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.4;
  text-align: right;
  margin: 0;
}
.membership-item--enrollment {
  color: #757575;
  flex-grow: 1;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.4;
  text-align: right;
  margin: 0;
  padding-right: 0.4rem;
}
.membership-item--price {
  margin-top: 5px;
  font-weight: 700;
  color: #757575;
}
.membership-item--glance {
  align-items: baseline;
  display: flex;
  flex-grow: 3;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 1;
  margin-top: 0;
}
.membership-item .summary-gif-separator {
  border-style: solid;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: solid;
  border-width: 1px;
  border-color: #e7e3e3;
}
.membership-item .summary-associates-price,
.membership-item .summary-associates-name,
.membership-item .summary-label-total,
.membership-item .text-promo-applied,
.membership-item .summary-label-associate,
.membership-item .summary-label-membership {
  display: flex;
}
.membership-item .summary-gif-bottom {
  background-color: #D9D9D9;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -18px;
  padding-left: 10px;
  padding-right: 12px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.membership-item .summary-gif-section {
  background-color: rgb(255, 255, 255);
  padding-top: 10px;
  margin: -20px -20px 0px;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-left: 10px;
}
.membership-item .summary-gif-total {
  background-color: rgb(255, 255, 255);
  margin: -20px -20px 0px;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding-left: 10px;
}
.membership-item .summary-label-gif {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1rem;
  margin: 0;
  color: #333;
  text-transform: none;
}
.membership-item .summary-gif-header {
  background-color: rgb(153, 204, 255);
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  padding-left: 10px;
  padding-right: 12px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  height: 60px;
}
.membership-item .summary-gif-header-top {
  margin-top: -10px;
}
.membership-item .summary-label-gif {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1rem;
  margin: 0;
  color: #333;
  text-transform: none;
}
.membership-item .summary-label-gif-for {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  padding-left: 2px;
  margin: 0;
  color: #333;
  text-transform: none;
}
.membership-item .summary-label-details {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  padding-left: 2px;
  font-size: 0.75rem;
  text-transform: none;
  margin: 0;
  color: #333;
}
.membership-item .summary-label-details-item {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding-left: 4px;
  font-size: 0.75rem;
  margin: 0;
  text-transform: none;
  color: #757575;
}
.membership-item .summary-label-details-item-cost {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding-left: 4px;
  font-size: 0.75rem;
  margin: 0;
  text-transform: none;
  color: #757575;
  flex-grow: 1;
  text-align: right;
}
.membership-item .summary-label-details-item-discount {
  color: #757575;
  flex-grow: 1;
  font-size: 0.75rem;
  text-decoration: line-through;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  text-align: right;
  margin: 0;
}
.membership-item .summary-label-details-center {
  display: flex;
  align-items: center;
}
.membership-item .summary-gif-message {
  min-width: 85px;
}
.membership-item .summary-gif-message-details {
  min-width: 180px;
}
.membership-item .summary-label-details-width {
  width: 55%;
}
.membership-item .summary-label-details-cost-width {
  width: 25%;
  margin-left: 10px;
}
.membership-item .details-cost-space {
  margin-left: 20px;
}
.membership-item--promo {
  align-items: flex-end;
  display: flex;
  flex-grow: 3;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 1;
  margin-top: 0;
}
.membership-item--promo-applied {
  flex-grow: 2;
  font-size: 0.75rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #3bb54a;
  text-align: left;
  margin: 0;
}
.membership-item--promo-applied::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: transparent url("~/public/images/icons/check.svg") no-repeat center center;
  background-size: cover;
  position: relative;
  margin-right: 0.5rem;
  top: 0.125rem;
}
.membership-item--cost {
  color: #757575;
  flex-grow: 1;
  font-size: 0.875rem;
  text-decoration: line-through;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  text-align: right;
  margin: 0;
}
.membership-item--enroll {
  align-items: baseline;
  display: flex;
  flex-grow: 3;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 1;
  margin-top: 0;
  height: 30px;
}
.membership-item--fees {
  order: 2;
}
.membership-item .cost-adjustments {
  text-align: right;
  flex-grow: 3;
  flex-basis: 100%;
}
.membership-item--details {
  order: 3;
  flex-grow: 3;
  height: max-content;
}
.membership-item--additional-note {
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 700;
}
.membership-item--details-hide {
  cursor: pointer;
  color: var(--blue);
  line-height: 1.4;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 12px;
  width: auto;
  font-size: 0.8rem;
}
.membership-item--details-view {
  order: 4;
  flex-grow: 1;
  align-self: flex-start;
  cursor: pointer;
  color: var(--blue);
  line-height: 1.4;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 12px;
  width: auto;
  font-size: 16px;
}
.membership-item--details-edit {
  text-align: right;
}
.membership-item--details-edit .btn {
  margin-left: 0.5rem;
}
.membership-item--details-open {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membership-item--note {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.4;
  margin: 0 0 1rem 0;
  white-space: pre-line;
}
.membership-item--fee {
  float: right;
}
.membership-item--fee p {
  text-align: left;
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0;
}
.membership-item:first-child {
  margin-top: 0;
}
.membership-item--group-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}
.membership-item--group-info .summary-value-grey {
  line-height: 1;
}
@media screen and (min-width: 48rem) {
  .membership-item--details-toggle {
    flex-grow: 1;
    align-self: flex-start;
  }
  .membership-item--group-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
  }
  .membership-item--first-column {
    width: calc(33% - 0.5rem);
    padding-right: 0.25rem;
  }
  .membership-item--second-column {
    width: calc(33% - 0.5rem);
    padding-right: 0.25rem;
  }
  .membership-item--third-column {
    width: calc(33% - 0.5rem);
    padding-right: 0.25rem;
  }
}
@media screen and (max-width: calc(48rem - 1px)) {
  .membership-item .summary-gif-header {
    flex-direction: column;
    align-items: flex-start;
    height: 120px;
  }
  .membership-item .summary-text-end {
    text-align: end;
  }
  .membership-item .summary-email {
    margin-bottom: 8px;
  }
  .membership-item .summary-associates-price,
  .membership-item .summary-label-total,
  .membership-item .summary-associates-name,
  .membership-item .summary-label-associate,
  .membership-item .summary-label-membership {
    display: block;
  }
  .membership-item .summary-label-details-cost-width,
  .membership-item .details-cost-space {
    margin-left: 0px;
  }
  .membership-item .summary-gif-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .membership-item .summary-gift-responsive {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .membership-item--first-column {
    padding-right: 0.25rem;
  }
  .membership-item--second-column {
    padding-right: 0.25rem;
  }
  .membership-item--third-column {
    padding-right: 0.25rem;
  }
}

.none {
  display: none;
}

