.footer {
  color: var(--text-dark);
  font-size: 0.75rem;
  height: 4.125rem;
  margin: 0;
  background-color: var(--white);
  text-align: center;

  &--links {
    margin: 0;
    padding: 1.5625rem 0 0 1rem;
    list-style-type: none;

    li {
      padding: 0;
      display: inline-block;
      list-style-type: none;
      margin: 0 0.5rem;

      a {
        padding-right: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.14;
        text-decoration: underline;
        color: #757575;
      }
    }
  }

  &--label {
    margin-top: 0.5rem;

    span {
      font-size: 0.775rem;
    }    
  }
}
