@import "../../styles/dims";

.recipient-thank-you-page {
  background-color: var(--white);

  &--next {
    text-align: center;
  }

  &--link {
    color: var(--blue);
    text-decoration: underline;
  }

  &--support {
    text-align: center;
  }

  &--submit {
    // margin-bottom: 3.75rem;
  }

  @media screen and (min-width: $breakpoint-main) {
    &--next {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &--questions {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

.btn-med {
  padding: 0.6rem 1rem;
}
