.promo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
}
.promo--bar {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.promo--promo-section {
  color: #757575;
  font-weight: 400;
  width: fit-content;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
}
.promo--summary {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.promo--promo-code {
  padding-right: 0;
  padding-top: 0;
  max-width: 240px;
}
.promo--promo-code input {
  text-transform: uppercase;
}
.promo--promo {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}
.promo--promo-code-code {
  flex: auto;
}
.promo--promo-code-message {
  flex: initial;
  width: 245px;
  margin-left: 2rem;
}
.promo--promo-code-btn {
  flex: initial;
  display: flex;
  justify-content: flex-end;
}
.promo--btn {
  margin-top: 1.5rem;
}
.promo--message {
  margin: 0 0 0.5rem 0;
  color: #3bb54a;
  position: relative;
}
.promo--message::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: transparent url("~/public/images/icons/dot.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0.125rem;
  left: -1.7rem;
  margin-right: 0.5rem;
}
.promo--title-message {
  margin: 0 0 0.5rem 0;
  color: #3bb54a;
  position: relative;
  font-weight: bold;
  padding-bottom: 10px;
}
.promo--title-message::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: transparent url("~/public/images/icons/check2.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0.125rem;
  left: -1.7rem;
  margin-right: 0.5rem;
}
.promo--error-message {
  margin: 0 0 0.5rem 0;
  color: #d52b1e;
  position: relative;
}
.promo--error-message::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: transparent url("~/public/images/icons/dot-red.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0.125rem;
  left: -1.7rem;
  margin-right: 0.5rem;
}
.promo--error-title-message {
  margin: 0 0 0.5rem 0;
  color: #d52b1e;
  position: relative;
  font-weight: bold;
  padding-bottom: 10px;
}
.promo--error-title-message::before {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background: transparent url("~/public/images/icons/exclamation.svg") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0.125rem;
  left: -1.7rem;
  margin-right: 0.5rem;
}
@media screen and (max-width: 48rem) {
  .promo--promo-code-message {
    order: 3;
  }
  .promo--promo-code {
    max-width: 150px;
  }
}
.promo p {
  margin: 0;
}
.promo p.promo--message {
  font-size: 0.875rem;
}
.promo p.promo--title-message {
  font-size: 0.875rem;
}
.promo p.promo--error-message {
  font-size: 0.875rem;
}
.promo p.promo--error-title-message {
  font-size: 0.875rem;
}

