@import "../../styles/dims";

.checkout-page {
  background-color: var(--white);

  &--blurb {
    p {
      margin-bottom: 0;
      padding-right: 0;
    }
  }

  &--secure-icon {
    height: 1.5625rem;
    width: 1.1875rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.125rem;
    margin-right: 0.5rem;
  }

  &--privacy {
    text-align: center;
    margin-bottom: 0.5rem;

    a {
      text-decoration: underline;
      font-size: 0.875rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: var(--text-dark);
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media screen and (min-width: $breakpoint-main) {
    .hero-no-image {
      .hero--inner {
        padding-top: 3.75rem;
        padding-bottom: 1.875rem;
      }
    }
  }
}
