.card {
  background: #fff;
  display: flex;
  padding: 1rem 1.25rem;
  border: 1px solid var(--gray-card-border);
}
.card-padding-all {
  padding: 1rem 1.25rem;
}
.card-padding-no-top {
  padding: 0 1.25rem 1rem 1.25rem;
}
.card-padding-no-bottom {
  padding: 1rem 1.25rem 0 1.25rem;
}
.card-padding-vertical {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
}
.card-padding-horizontal {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
}
.card-padding-top {
  padding-top: 1rem;
}
.card-padding-bottom {
  padding-bottom: 1rem;
}
.card-padding-left {
  padding-left: 1.25rem;
}
.card-padding-right {
  padding-right: 1.25rem;
}
.card-padding-none {
  padding: 0;
}
.card.card-vertical {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (min-width: 48rem) {
  .card {
    padding: 1.125rem 1.25rem;
  }
  .card-padding-all {
    padding: 1.125rem 1.25rem;
  }
  .card-padding-no-top {
    padding: 0 1.25rem 1.125rem 1.25rem;
  }
  .card-padding-no-bottom {
    padding: 1.125rem 1.25rem 0 1.25rem;
  }
  .card-padding-vertical {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .card-padding-horizontal {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .card-padding-top {
    padding-top: 1.125rem;
  }
  .card-padding-bottom {
    padding-bottom: 1.125rem;
  }
  .card-padding-left {
    padding-left: 1.25rem;
  }
  .card-padding-right {
    padding-right: 1.25rem;
  }
  .card-padding-none {
    padding: 0;
  }
}

