@import "../../styles/dims";

$padding-vertical: 1rem;
$padding-horizonal: 1.25rem;

.section {
  position: relative;

  &-padding-all {
    padding: $padding-vertical $padding-horizonal;
  }

  &-padding-no-top {
    padding: 0 $padding-horizonal $padding-vertical $padding-horizonal;
  }

  &-padding-no-bottom {
    padding: $padding-vertical $padding-horizonal 0 $padding-horizonal;
  }

  &-padding-vertical {
    padding: 0;
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
  }

  &-padding-horizontal {
    padding: 0;
    padding-left: $padding-horizonal;
    padding-right: $padding-horizonal;
  }

  &-padding-top {
    padding: 0;
    padding-top: $padding-vertical;
  }

  &-padding-bottom {
    padding: 0;
    padding-bottom: $padding-vertical;
  }

  &-padding-left {
    padding: 0;
    padding-left: $padding-horizonal;
  }

  &-padding-right {
    padding: 0;
    padding-right: $padding-horizonal;
  }

  &-padding-none {
    padding: 0;
  }

  > p:first-child {
    margin-top: 0;

    &::first-line {
      line-height: 1;
    }
  }

  &.alt-section {
    background: var(--light-fill);
  }

  @media screen and (min-width: $breakpoint-main) {
    padding: $tablet-padding-vertical 0;

    &-padding-all {
      padding: $tablet-padding-vertical 0;
    }

    &-padding-no-top {
      padding: 0 0 $tablet-padding-vertical 0;
    }

    &-padding-no-bottom {
      padding: $tablet-padding-vertical 0 0 0;
    }

    &-padding-vertical {
      padding-top: $tablet-padding-vertical;
      padding-bottom: $tablet-padding-vertical;
    }

    &-padding-horizontal {
      padding-left: 0;
      padding-right: 0;
    }

    &-padding-top {
      padding-top: $tablet-padding-vertical;
    }

    &-padding-bottom {
      padding-bottom: $tablet-padding-vertical;
    }

    &-padding-left {
      padding-left: 0;
      padding-right: 0;
    }

    &-padding-right {
      padding-left: 0;
      padding-right: 0;
    }

    &-padding-none {
      padding: 0;
    }

    .section--inner {
      padding-left: 1rem;
      padding-right: 1rem;

      // width: 100%;
      // max-width: $non-bleed-max-width;
      // margin-left: auto;
      // margin-right: auto;
    }

    // &.section-left-desktop h2,
    // &.section-left-desktop h3,
    // &.section-left-desktop p {
    // 	text-align: left;
    // }
    // &.section-left-desktop .btn {
    // 	margin-left: 0;
    // }
  }
}

.section--title {
  color: #757575;
  font-size: 1.325rem;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}