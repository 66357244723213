@import '../../styles/dims';

.enrollment-form {
  &--form-group {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    .text-input {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }

  &--content {
    .text-input {
      &:first-child {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (min-width: $breakpoint-main) {
    &--content {
      display: flex;

      .text-input {
        &:first-child {
          margin-right: 0.5rem;
        }

        &:last-child {
          margin-left: 0.5rem;
        }
      }

      .text-input--description {
        text-align: justify;
      }
    }

    &--city {
      margin-right: 0.5rem !important;
    }
  }

  &--submit {
    margin-top: 3.25rem;
  }

  &--first-name {
    flex-basis: 90%;
  }

  &--last-name {
    flex-basis: 100%;
  }

  &--middle-initial {
    margin-right: 0;
    flex-basis: 15%;
  }

  &--address-1 {
    flex-basis: 50%;
    margin-right: 0 !important;
  }

  &--address-2 {
    flex-basis: 50%;
  }

  &--phone-number {
    flex-basis: 50%;
    margin-right: 0 !important;
  }

  &--birth-year {
    flex-basis: 15%;
    margin-left: 0;
  }

  &--city {
    flex-basis: 50%;
    margin-right: 0.5rem;
  }

  &--state {
    flex-basis: 30%;
  }

  &--zip-code {
    flex-basis: 20%;
  }

  &--membership-number {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-basis: 49%;
  }

  &--btns {
    &.enrollment-form--btns-multi {
      margin-top: 3.25rem;

      .enrollment-form--cacncel {
        margin-top: 1rem;
      }
    }
  }

  .text-input--description {
    text-align: justify;
  }
}
