.cart-page {
  background-color: var(--white);
}
.cart-page .total {
  margin-bottom: 2rem;
}
.cart-page .promo {
  margin-bottom: 2rem;
}
.cart-page--form {
  width: 100%;
}
.cart-page--submit {
  margin-bottom: 0.625rem;
}
.cart-page--add-another {
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
}
.cart-page--empty-title {
  margin: 1rem 0;
}
.cart-page--empty-text {
  margin: 1rem 0;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.cart-page--empty-button {
  margin: 3.25rem 0 1rem 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
}
.cart-page--buttons-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .cart-page {
    background-color: var(--white);
  }
  .cart-page--buttons-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
  }
  .cart-page--buttons {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    max-width: 37.5rem;
    width: 100%;
  }
  .cart-page--buttons .btn-block {
    max-width: none;
  }
  .cart-page .cart-page--submit {
    width: calc(50% - 0.5rem);
  }
}

.section--title {
  font-size: 1rem;
}

.btn-med {
  padding: 0.6rem 1rem;
}

